import Div from "@jumbo/shared/Div";
import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  FormHelperText,
} from "@mui/material";

import { useCalendarContext } from "../context";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { timings2 } from "../../../../utils/constants/appActions";
import { useAppMutation } from "../../../../services";
import teacherServices from "../../../../services/teacher-services";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, types, theme) {
  console.log(name, types);
  return {
    fontWeight:
      types && types.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const namesWith = [
  { label: "Evaluation", value: "evaluation" },
  { label: "Courses", value: "courses" },
];

const Form = () => {
  const [names, setNames] = React.useState([]);

  const { mutate, error, isError, data, isLoading, isSuccess } = useAppMutation(
    teacherServices.saveAvailability,
  );
  const { mutate: isEvaluator, data: evaluator } = useAppMutation(
    teacherServices.isEvaluator,
  );
  const { globalState, setGlobalState } = useCalendarContext();
  const [errMsg, setErrMsg] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const { t } = useTranslation();

  const [values, setValues] = React.useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    comments: "",
    noOfStudents: 1,
    repeated: "no-repeat",
    types: ["courses"],
  });

  const {
    mutate: deleteAvailability,
    isSuccess: isDUSuccess,
    data: duResult,
  } = useAppMutation(teacherServices.deleteAvailability);

  React.useEffect(() => {
    if (isSuccess) {
      resetValues();
      hideDialog();
    } else if (isError) {
      setErrMsg(t("errMsg.saving"));
    }
  }, [isError, isSuccess]);

  React.useEffect(() => {
    if (evaluator) {
      setNames([
        { label: "Evaluation", value: "evaluation" },
        { label: "Courses", value: "courses" },
      ]);
    } else {
      setNames([]);
    }
  }, [evaluator]);

  React.useEffect(() => {
    if (globalState && globalState.event) {
      console.log("Cancel-------------", globalState.event);
      isEvaluator();
      setValues({ ...values, ...globalState.event });
    }
  }, [globalState]);

  React.useEffect(() => {
    if (duResult && isDUSuccess) {
      setGlobalState({
        ...globalState,
        refreshList: true,
        openUnavailabilityForm: false,
      });
    }
  }, [duResult, isDUSuccess]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDeleteAvailability = () => {
    const { scheduleId } = values;
    deleteAvailability(scheduleId);
  };

  const handleAvailability = () => {
    if (values.noOfStudents > 100) {
      setErrMsg(t("common.errStudentNo"));
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
      return; // Prevent further execution if validation fails
    }
    if (
      values.types &&
      values.repeated &&
      values.startTime &&
      values.startDate &&
      values.endDate &&
      values.endTime
    ) {
      const selectedStartDateTime = new Date(
        `${values.startDate}T${values.startTime}`,
      );
      let selectedEndDateTime = new Date(`${values.endDate}T${values.endTime}`);

      if (values.scheduleId) {
        selectedEndDateTime = new Date(`${values.startDate}T${values.endTime}`);
      }

      const form = {
        startDate: selectedStartDateTime,
        endDate: selectedEndDateTime,
        comments: values.comments,
        types: values.types,
        repeated: values.repeated,
        scheduleId: values.scheduleId,
        noOfStudents: values.noOfStudents,
      };

      const days = calculateDays({
        startDate: form.startDate,
        endDate: form.endDate,
      });

      if (days > 6) {
        if (values.repeated !== "no-repeat") {
          setErrMsg(t("errMSg.eventType"));
          setTimeout(() => {
            setErrMsg("");
          }, 5000);

          return;
        }
      } else if (days > 0 && days <= 6) {
        if (values.repeated === "no-repeat" || values.repeated === "weekly") {
        } else {
          setErrMsg(t("errMsg.eventType"));
          setTimeout(() => {
            setErrMsg("");
          }, 5000);

          return;
        }
      }

      mutate(form);
    } else {
      setErrMsg(t("common.inputError"));
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    }
  };

  const resetValues = () => {
    setValues({
      startDate: "",
      startTime: "",
      comments: "",
      endDate: "",
      endTime: "",
      types: ["courses"],
      repeated: "no-repeat",
      noOfStudents: 1,
    });
  };

  const hideDialog = () => {
    setGlobalState({
      ...globalState,
      event: undefined,
      refreshList: true,
      openUnavailabilityForm: false,
    });
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setValues({
      ...values,
      types: typeof value === "string" ? value.split(",") : value,
    });
  };

  const calculateDays = ({ startDate, endDate }) => {
    var admission = moment(startDate);
    var discharge = moment(endDate);
    return discharge.diff(admission, "days");
  };

  const renderRepeatedSelect = ({ startDate, endDate }) => {
    const days = calculateDays({ startDate, endDate });

    return (
      <FormControl
        sx={{
          width: { xs: "100%", sm: "100%", xl: "100%" },
          mt: 0.5,
          mb: 2,
        }}
      >
        <Div sx={{}}>
          <InputLabel sx={{ ml: 0.25 }} id="demo-simple-select-label">
            {t("teacher-availability-form.title.select")}
          </InputLabel>
          <Select
            required
            sx={{ width: "100%", maxWidth: "100%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.repeated}
            onChange={handleChange("repeated")}
            label={t("common.select")}
          >
            <MenuItem value="no-repeat">
              {t("teacher-availability-form.title.noRepeat")}
            </MenuItem>

            {days === 0 && (
              <MenuItem value="daily">
                {t("teacher-availability-form.title.daily")}
              </MenuItem>
            )}
            {days === 0 && (
              <MenuItem value="weekly">
                {t("teacher-availability-form.title.weekly")}
              </MenuItem>
            )}
            {days === 0 && (
              <MenuItem value="week-day">
                {t("teacher-availability-form.title.everyday")}
              </MenuItem>
            )}

            {days > 0 && days <= 6 && (
              <MenuItem value="weekly">
                {t("teacher-availability-form.title.weekly")}
              </MenuItem>
            )}
          </Select>
        </Div>
      </FormControl>
    );
  };

  return (
    <Div>
      <FormControl noValidate autoComplete="off">
        <Div
          sx={{
            "& .MuiTextField-root": {
              mb: 2,
              mt: 0.5,
            },
          }}
        >
          <FormControl fullWidth sx={{ mt: 0.5 }}>
            <TextField
              id="date"
              label={t("teacher-availability-form.title.startDate")}
              type="date"
              value={values.startDate}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange("startDate")}
            />
          </FormControl>
          {!values.scheduleId && (
            <FormControl fullWidth>
              <TextField
                id="date"
                label={t("teacher-availability-form.title.endDate")}
                type="date"
                value={values.endDate}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange("endDate")}
              />
            </FormControl>
          )}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">
              {t("teacher-availability-form.title.startTime")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.startTime}
              label={t("teacher-availability-form.title.startTime")}
              onChange={handleChange("startTime")}
            >
              {timings2.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">
              {t("teacher-availability-form.title.endTime")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.endTime}
              label={t("teacher-availability-form.title.endTime")}
              onChange={handleChange("endTime")}
            >
              {timings2.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label="No. of Students"
              value={values.noOfStudents}
              name="noOfStudents"
              onChange={handleChange("noOfStudents")}
              type="number"
              inputProps={{ min: 1 }}
            />
          </FormControl>

          {names?.length > 0 && (
            <FormControl fullWidth sx={{ mb: 1.5 }}>
              <InputLabel id="demo-multiple-chip-label">
                {t("teacher-availability-form.title.progOrEval")}
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={values.types}
                onChange={handleChange1}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label={t("teacher-availability-form.title.progOrEval")}
                  />
                }
                renderValue={(selected) => (
                  <Div sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Div>
                )}
                MenuProps={MenuProps}
              >
                {names.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={getStyles(option.label, values.types, theme)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {renderRepeatedSelect({
            startDate: values.startDate,
            endDate: values.endDate,
          })}

          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-static"
              label={t("teacher-availability-form.title.commentBox")}
              multiline
              rows={1}
              value={values.comments}
              name="comments"
              onChange={handleChange("comments")}
            />
          </FormControl>
          <Stack
            sx={{
              mb: 2,
              width: "90%",
              mx: "auto",
              display: "block",
              position: "relative", // Add position relative to enable absolute positioning of loader
            }}
            spacing={2}
          >
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
          </Stack>

          <ButtonGroup
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
            disableElevation
            variant="contained"
            color={"warning"}
          >
            <Button
              type="submit"
              variant="contained"
              size="medium"
              sx={{
                width: "30%",
              }}
              onClick={hideDialog}
            >
              {t("common.cancelBtn")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              sx={{
                width: "30%",
                ml: 0.5,
                mr: 0.5,
              }}
              onClick={handleAvailability}
            >
              {t("common.saveBtn")}
            </Button>

            {values.scheduleId && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  width: "30%",
                  ml: 0.5,
                  mr: 0.5,
                }}
                onClick={handleDeleteAvailability}
              >
                {t("common.deleteBtn")}
              </Button>
            )}
          </ButtonGroup>
        </Div>
      </FormControl>
    </Div>
  );
};

export default Form;
