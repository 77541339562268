import React, { useEffect } from "react";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppMutation } from "../../../services";
import parentServices from "../../../services/parent-services";
import studentServices from "../../../services/student-services";
import { useTranslation } from "react-i18next";
import {
  countryFormattingRules,
  PHONE_COUNTRIES,
} from "../../../utils/constants/appActions";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const Signup = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
    >
      <InnerSignup />
    </GoogleReCaptchaProvider>
  );
};

const InnerSignup = () => {
  const {
    mutate: signUp,
    error,
    isError,
    isLoading,
    data: result,
    isSuccess,
  } = useAppMutation(studentServices.signUp);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isSuccess) {
      window.dataLayer = window.dataLayer || [];
      const { email, firstName, lastName, phone, country } = values;
      const { code, countryCode, label } = country;
      console.log(values);
      window.dataLayer.push({
        event: "StudentSignUp",
        studentEmail: email,
        studentFirstName: firstName,
        studentLastName: lastName,
        studentPhone: phone,
        studentCountry: label,
      });
      navigate("/student/signup/success");
      // console.log(isSuccess);
    }
    if (isError && error) {
      if (error && error.includes("User Already Exists")) {
        setErrMsg(t("student-signup.title.alreadyEmailMsg"));
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
      } else {
        setErrMsg(t("student-signup.title.signUpErrorMsg"));
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
      }
    }
    console.log(
      "Signup-----------------------",
      isError,
      error,
      isSuccess,
      result,
    );
  }, [isSuccess, isError]);

  const [values, setValues] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    country: {
      countryCode: "FR",
      label: "France",
      code: "33",
      suggested: true,
    },
    phone: "",
    showPassword: false,
    subscribeNewsLetter: false,
    terms: false,
  });

  const handleChange = (prop) => (event) => {
    let value = event.target.value;

    // Remove leading zeros from the phone number
    if (prop === "phone") {
      value = value.replace(/^0+/, "");
    }

    const selectedCountry = values.country.code;
    const formattingRule = countryFormattingRules.find(
      (rule) => rule.code === selectedCountry,
    );

    if (formattingRule) {
      const matches = value.match(formattingRule.pattern);

      if (matches) {
        // Remove undefined and join the matched groups with the specified format
        value = matches
          .slice(1)
          .filter((match) => match !== undefined)
          .join(" ");
      }
    }

    setValues({ ...values, [prop]: value });
  };

  const handleCountryChange = (event, newValue) => {
    console.log(newValue);
    setValues({ ...values, country: newValue });
  };

  const handleSubsribeChange = (event) => {
    setValues({ ...values, subscribeNewsLetter: event.target.checked });
  };

  const handleTermChange = (event) => {
    setValues({ ...values, terms: event.target.checked });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const [phoneDetails, setPhoneDetails] = React.useState({
    countryCode: "",
    localNumber: "",
  });

  const handleChangePhone = (phone, country) => {
    const countryCode = country.dialCode; // Get the country code
    const localNumber = phone.slice(countryCode.length); // Extract the local number by removing the country code
    const formattedPhone = `+${countryCode}${phone.replace(countryCode, "")}`;
    const label = country.name;

    console.log("Country Code:", countryCode);
    console.log("Local Number:", localNumber);
    console.log("Full Number:", phone);
    console.log("Formatted Phone Number:", formattedPhone);
    console.log("label:", label);

    setPhoneDetails({ countryCode, localNumber });
    setValues({ ...values, phone });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignup = () => {
    console.log(values);
    setErrMsg("");

    if (
      !values.email ||
      !values.firstName ||
      !values.lastName ||
      !values.password ||
      !values.country ||
      !values.phone
    ) {
      setErrMsg(t("student-signup.title.requiredFieldErrorMsg"));
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    } else if (!values.terms) {
      setErrMsg(t("student-signup.title.termsErrorMsg"));
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    } else if (!values.phone) {
      setErrMsg("Invalid Full Phone Number");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return;
    } else {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available..");
        return;
      }

      executeRecaptcha("studentSigunForm").then((gReCaptchaToken) => {
        const obj = {
          ...values,
          location: { country: values.country.code },
          phone: {
            fullNumber: values.phone,
            code: phoneDetails.countryCode,
            number: phoneDetails.localNumber,
          },
          token: gReCaptchaToken,
        };
        console.log("SignUp------------", obj);
        signUp(obj);
      });
    }
  };

  const handleSignup0 = () => {
    console.log(values);
    setErrMsg("");

    if (
      !values.email ||
      !values.firstName ||
      !values.lastName ||
      !values.password ||
      !values.country ||
      !values.phone
    ) {
      setErrMsg(t("student-signup.title.requiredFieldErrorMsg"));
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    } else if (!values.terms) {
      setErrMsg(t("student-signup.title.termsErrorMsg"));
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    } else if (!values.phone) {
      setErrMsg("Invalid Full Phone Number");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return;
    } else {
      const obj = {
        ...values,
        location: { country: values.country.code },
        phone: {
          fullNumber: values.phone,
          code: phoneDetails.countryCode,
          number: phoneDetails.localNumber,
        },
      };
      console.log("SignUp------------", obj);
      signUp(obj);
    }
  };

  if (location.pathname === "/student/signup/success" || isSuccess) {
    return (
      <>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 700,
          }}
        >
          <Card sx={{ padding: 3, width: 600, height: 280 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <img src="/images/logos/logo.png" alt="Logo" width="150" />
              <Typography variant="h4" color="success" fontWeight={500} mb={2}>
                {t("student-signup.title.successMsg")}
              </Typography>
              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/images/logos/success3.png"
                  alt="Success"
                  width="50"
                  style={{ marginBottom: "10px" }}
                />
                <Typography variant={"body1"} mb={0} mr={3}>
                  {t("student-signup.title.clickTo")}
                  <Link
                    href="/student/login"
                    color={"inherit"}
                    underline={"none"}
                    sx={{
                      "&:hover": {
                        color: "#454545", // Change the color to red on hover
                      },
                      textDecoration: "underline",
                      ml: 0.5,
                    }}
                  >
                    {t("student-signup.title.login")}
                  </Link>
                </Typography>
              </Div>
            </CardContent>
          </Card>
        </Div>
      </>
    );
  }

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: 1,
            p: 4,
          }}
        >
          <Div
            sx={{
              mb: 2, // Adjust margin as needed
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src="/images/logos/logo.png" alt="Logo" width="150" />
          </Div>

          <Div sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
            <Typography variant={"h3"} color={"inherit"} fontWeight={500}>
              {t("student-signup.title.signupHeading")}
            </Typography>
          </Div>

          <Div sx={{ mt: 1, mb: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                required
                id="email"
                label={t("common.email")}
                //InputLabelProps={{shrink: true}}
                fullWidth
                value={values.email}
                onChange={handleChange("email")}
              />
            </FormControl>
          </Div>
          <Div sx={{ mt: 1, mb: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                required
                id="firstName"
                label={t("common.firstName")}
                fullWidth
                value={values.firstName}
                onChange={handleChange("firstName")}
              />
            </FormControl>
          </Div>

          <Div sx={{ mt: 1, mb: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                required
                id="lastName"
                label={t("common.lastName")}
                fullWidth
                value={values.lastName}
                onChange={handleChange("lastName")}
              />
            </FormControl>
          </Div>

          <Div sx={{ mt: 1, mb: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                {t("common.password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("common.password")}
                required
              />
            </FormControl>
          </Div>

          <Div sx={{ mt: 1, mb: 2 }}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <PhoneInput
                country={"fr"} // Default country code
                value={values.phone}
                onChange={handleChangePhone}
                inputStyle={{ width: "100%" }} // Ensures full width styling
                required
              />
            </FormControl>
          </Div>

          <Div sx={{ display: "flex", alignItems: "center", mb: 2, ml: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.subscribeNewsLetter}
                  onChange={handleSubsribeChange}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#454545",
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  {t("parent-signup.title.subscriptionMsg")}
                </Typography>
              }
            />
          </Div>

          <Div sx={{ display: "flex", alignItems: "center", mb: 2, ml: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  onChange={handleTermChange}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: "#454545",
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  {t("parent-signup.title.text1")}{" "}
                  <Link
                    underline="none"
                    href="/cgu"
                    target="_blank" // Opens in a new tab/window
                    rel="noopener noreferrer" // Security measure
                    ml={0.5}
                    color={"#007BFF"}
                  >
                    {t("parent-signup.title.text2")}
                  </Link>{" "}
                  {t("parent-signup.title.text3")}{" "}
                  <Link
                    underline="none"
                    href="/policy"
                    target="_blank" // Opens in a new tab/window
                    rel="noopener noreferrer" // Security measure
                    ml={0.5}
                    color={"#007BFF"}
                  >
                    {t("parent-signup.title.text4")}
                  </Link>{" "}
                  {t("parent-signup.title.text5")}
                </Typography>
              }
            />
          </Div>
          {/*   <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.terms}
                                    onChange={handleTermChange}
                                    sx={{
                                        color: 'gray',
                                        '&.Mui-checked': {
                                            color: '#454545',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    Termes et conditions{' '}
                                    <Link href="https://app.deliverydigital.fr/conditions-generales-dutilisation/" target="_blank" rel="noopener">
                                        https://app.deliverydigital.fr/conditions-generales-dutilisation/
                                    </Link>
                                </Typography>
                            }
                        />
                    </Div> */}

          <Stack
            sx={{
              mb: 2,
              width: "90%",
              mx: "auto",
              display: "block",
              position: "relative",
            }}
            spacing={2}
          >
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
          </Stack>

          <Button
            variant="contained"
            sx={{
              mb: 2,
              width: "90%",
              mx: "auto",
              display: "block",
              position: "relative",
            }}
            onClick={handleSignup}
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  color: "#FFFFFF",
                  position: "absolute",
                  top: "50%",
                  right: "35%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            {isLoading
              ? t("student-signup.title.signingupBtn")
              : t("student-signup.title.signupBtn")}
          </Button>

          <Div sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
            <Typography variant={"body1"}>
              {t("student-signup.title.alreadyMsg")}
              <Link
                href="/student/login"
                color={"inherit"}
                underline={"none"}
                sx={{
                  "&:hover": {
                    color: "#454545",
                  },
                  ml: 0.5,
                }}
              >
                {t("student-signup.title.signIn")}
              </Link>
            </Typography>
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};
export default Signup;
