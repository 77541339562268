import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";
import React, { useState } from "react";
import { useAppMutation } from "../../../../services";
import parentServices from "../../../../services/parent-services";
import studentService from "../../../../services/student-services";
import { isPastDate } from "../../../../utils/constants/appActions";
import { useBookingContext } from "../context";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import AddBookingByTeacher from "./add-booking-by-teacher";
import AddBookingByDate from "./add-booking-by-date";

const AddBooking = ({}) => {
  const { availableDates, setAvailableDates } = useBookingContext();
  const [showWarning, setShowWarning] = useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [type] = React.useState("courses");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const [pastDate, setPastDate] = useState(false);
  const [isEditable] = useState(true);
  const { globalState, setGlobalState } = useBookingContext();
  const [open, setOpen] = React.useState(false);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [sTimings, setSTimings] = useState([]);
  const [transaction, setTransaction] = React.useState({});
  const [recommendedTransaction, setRecommendedTransaction] = React.useState(
    {},
  );
  const [transactions, setTransactions] = React.useState([]);
  const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [teachers, setTeachers] = useState([]);
  const [values, setValues] = React.useState({
    startDate: "",
    startTime: "",
    title: "Student Booking",
    description: "",
    transactionId: "",
    teacherId: "",
    childId: "",
    studentid: "",
    meetingLink: "",
  });

  const {
    mutate: loadCoursesForBooking,
    isSuccess: isLASSuccess,
    data: lasData,
  } = useAppMutation(studentService.loadCoursesForBooking);

  const {
    mutate: saveBooking,
    isSuccess: isBSuccess,
    data: bResult,
    isLoading: isBLoading,
    isError: isBError,
  } = useAppMutation(parentServices.saveBooking);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (globalState.booking) {
      const booking = { ...values, ...globalState.booking };
      setValues(booking);
      const min = globalState.minDate;
      const max = globalState.maxDate;
      setPastDate(isPastDate(booking.sstartDate));
      setMinDate(min);
      setMaxDate(max);
      loadCoursesForBooking({
        transactionId: values.transactionId,
        date: booking.sstartDate,
      });
    }
  }, [globalState]);

  React.useEffect(() => {
    if (isBSuccess) {
      setGlobalState({
        ...globalState,
        booking: undefined,
        addEditBooking: false,
        refreshList: true,
      });
    } else if (isBError) {
      setErrMsg(t("errMsg.saving"));
    }
  }, [isBError, isBSuccess]);

  React.useEffect(() => {
    console.log("isLASSuccess", isLASSuccess, lasData);
    if (isLASSuccess && lasData) {
      const { transaction: sub, transactions } = lasData;

      console.log("Transactions.......", transaction, transactions);
      setTransactions([...transactions]);
      setRecommendedTransaction({ ...sub });

      const d = new Date(minDate);
      let sD = new Date(transaction.expiredDate);
      if (!values.transactionId) {
        setValues({
          ...values,
          transactionId: transaction?.transactionId,
        });
        setTransaction({ ...sub });
        sD = new Date(sub?.expiredDate);
      }
      d.setMonth(d.getMonth() + 3);

      if (d > sD) {
        setMaxDate(sD);
      } else {
        setMaxDate(d);
      }
    }
  }, [isLASSuccess]);

  const renderTransactionAlert = () => {
    if (!transactions || transactions?.length == 0) {
      return (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning" onClose={() => {}}>
            {t("calendar.subscribe")}
          </Alert>
        </Stack>
      );
    }

    return null;
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "childId") {
      /* loadCoursesForBooking({
                 studentId: event.target.value,
                 transactionId: values.transactionId,
                 date: values.sstartDate
             });*/
    } else if (prop === "transactionId") {
      const transactionId = event.target.value;
      const sub = transactions.find((ii) => ii.transactionId === transactionId);

      setValues({
        ...values,
        transactionId: sub.transactionId,
        teacherId: "",
      });

      setTransaction(sub);
      const d = new Date(minDate);
      const sD = new Date(sub.expiredDate);
      d.setMonth(d.getMonth() + 3);
      if (d > sD) {
        setMaxDate(sD);
      } else {
        setMaxDate(d);
      }
    }
  };

  const isDisableSave = () => {
    if (!transactions || transactions?.length == 0) {
      return true;
    }

    return false;
  };

  const handleSaveBooking = () => {
    if (values.startTime && values.startDate) {
      const selectedStartDateTime = new Date(
        `${values.startDate}T${values.startTime}`,
      );
      const currentTime = new Date();
      const timeDifference =
        selectedStartDateTime.getTime() - currentTime.getTime();
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert milliseconds to hours

      if (hoursDifference < 24) {
        setShowWarning(true);
        return; // Exit the function to show the warning message
      }
      const selectedEndDateTime = new Date(
        `${values.startDate}T${values.startTime}`,
      );
      selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + values.duration);

      const booking = {
        description: values.description,
        startDate: selectedStartDateTime,
        endDate: selectedEndDateTime,
        studentId: values.childId,
        bookingId: values.bookingId,
        teacherId: values.teacherId,
        transactionId: values.transactionId,
        duration: values.duration,
      };

      saveBooking(booking);
    } else {
      setErrMsg(t("common.inputError"));
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    }
  };

  return (
    <FormControl
      noValidate
      autoComplete="off"
      sx={{
        width: { xl: 500, sm: 400, md: 450, xs: "100%" },
        padding: "0",
        margin: "2",
      }}
    >
      <Box sx={{ marginLeft: -1, marginRight: -1 }}>
        {renderTransactionAlert()}

        {showWarning && (
          <Snackbar
            open={showWarning}
            autoHideDuration={6000}
            onClose={() => setShowWarning(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ width: "100%", marginTop: 2 }}
            spacing={2}
          >
            <Alert onClose={() => setShowWarning(false)} severity="warning">
              {t("calendar.notChange")}
            </Alert>
          </Snackbar>
        )}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="booking tabs"
          variant="fullWidth"
          sx={{ marginBottom: 2 }}
        >
          <Tab sx={{ px: "20%" }} label={t("parent.title.byDate")} />
          <Tab sx={{ px: "20%" }} label={t("parent.title.byTeacher")} />
        </Tabs>

        <FormControl fullWidth sx={{ mt: 2.5 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            {t("calendar.course")}
          </InputLabel>
          <Select
            disabled={pastDate || !isEditable}
            value={values.transactionId}
            label={t("calendar.course")}
            onChange={handleChange("transactionId")}
            renderValue={(selected) => {
              // Find the selected child based on the studentId
              const selectedChild = transactions?.find(
                (child) => child.transactionId === selected,
              );

              // Display only the selected child's name
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {selectedChild?.course?.name}
                </Box>
              );
            }}
          >
            {transactions.map((item) => (
              <MenuItem key={item.transactionId} value={item.transactionId}>
                <div>
                  {item.course.name}
                  <br />
                  {t("common.expired")}{" "}
                  {moment(item.expiredDate).format("YYYY-MM-DD")}
                  <br />
                  {t("common.bookingUtilize")} {item.bookingHours.totalHours}/
                  {item.noOfHours}
                </div>
                {false &
                  (item.transactionId ===
                    recommendedTransaction?.transactionId) && (
                  <div>
                    <Chip
                      label={t("common.recommended")}
                      color={"success"}
                      size={"small"}
                    />
                  </div>
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {activeTab === 0 && (
          <AddBookingByDate
            setMaxDate={setMaxDate}
            loadActiveTransactions={loadCoursesForBooking}
            availableDates={availableDates}
            minDate={minDate}
            maxDate={maxDate}
            type={type}
            values={values}
            setValues={setValues}
            pastDate={pastDate}
          />
        )}
        {activeTab === 1 && (
          <AddBookingByTeacher
            setMaxDate={setMaxDate}
            loadActiveTransactions={loadCoursesForBooking}
            availableDates={availableDates}
            minDate={minDate}
            maxDate={maxDate}
            type={type}
            values={values}
            setValues={setValues}
            pastDate={pastDate}
          />
        )}

        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            style={{ width: "100%" }}
            disabled={pastDate || !isEditable}
            id="outlined-multiline-static"
            label={t("common.desc")}
            multiline
            rows={2}
            value={values.description}
            name="description"
            onChange={handleChange("description")}
          />
        </FormControl>
        {
          <Box
            sx={{
              width: { xs: "100%", xl: "100%", sm: "100%", md: "100%" },
              mt: 2,
            }}
          >
            {t("parent.title.txt")}{" "}
          </Box>
        }
        <Stack
          sx={{
            mb: 2,
            // width: '90%',
            mx: "auto",
            display: "block",
            position: "relative",
            width: { xs: "100%", xl: "100%", sm: "100%", md: "100%" },
          }}
          spacing={2}
        >
          {errMsg && <Alert severity="error">{errMsg}</Alert>}
        </Stack>
        <ButtonGroup
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
          disableElevation
          variant="contained"
          color={"warning"}
        >
          <Button
            type="submit"
            variant="contained"
            size="medium"
            onClick={() => {
              setGlobalState({
                ...globalState,
                booking: undefined,
                addEditBooking: false,
              });
            }}
            sx={{}}
          >
            {t("common.closeBtn")}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            disabled={isDisableSave()}
            onClick={handleSaveBooking}
            sx={{
              ml: 0.5,
              mr: 0.5,
            }}
            loading={isBLoading}
          >
            {values.cancelled ? (
              <>Undo</>
            ) : values.bookingId ? (
              t("common.updateBtn")
            ) : (
              t("common.saveBtn")
            )}
          </LoadingButton>
        </ButtonGroup>
      </Box>
    </FormControl>
  );
};

export default AddBooking;
