import moment from "moment/moment";

export const APP_ACTIONS = {
  SET_CUSTOMIZER_VISIBILITY: "set-customizer-visibility",
  SET_CONTAINER_STYLE: "set-container-style",
  SET_APP: "set-app",
};

export const fullNumberRegex1 =
  /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;

export const findCountryByCode = (code) => {
  try {
    const index = COUNTRIES.findIndex((country) => country.code === code);
    return COUNTRIES[index];
  } catch (e) {}
  return null;
};

export const isBefore24Hours = (date) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  console.log("--------------------------", date, currentDate);
  return date < currentDate;
};

export const isPastDate = (date) => {
  const currentDate = new Date();
  // currentDate.setHours(0, 0, 0, 0);
  console.log("--------------------------", date, currentDate);

  // Fri Mar 15 2024 13:30:00 GMT+0500 (Pakistan Standard Time)
  // Thu Mar 14 2024 14:26:57 GMT+0500 (Pakistan Standard Time)
  console.log(date < currentDate);
  return new Date(date) <= currentDate;
};
export const COUNTRIES = [
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  {
    code: "AF",
    label: "Afghanistan",
    phone: "93",
    suggested: false,
  },
  {
    code: "AL",
    label: "Albania",
    phone: "355",
    suggested: false,
  },
  {
    code: "DZ",
    label: "Algeria",
    phone: "213",
    suggested: false,
  },
  {
    code: "AD",
    label: "Andorra",
    phone: "376",
    suggested: false,
  },
  {
    code: "AO",
    label: "Angola",
    phone: "244",
    suggested: false,
  },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
    suggested: false,
  },
  {
    code: "AR",
    label: "Argentina",
    phone: "54",
    suggested: false,
  },
  {
    code: "AM",
    label: "Armenia",
    phone: "374",
    suggested: false,
  },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: false,
  },
  {
    code: "AT",
    label: "Austria",
    phone: "43",
    suggested: false,
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
    suggested: false,
  },
  {
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
    suggested: false,
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    suggested: false,
  },
  {
    code: "BD",
    label: "Bangladesh",
    phone: "880",
    suggested: false,
  },
  {
    code: "BB",
    label: "Barbados",
    phone: "1-246",
    suggested: false,
  },
  {
    code: "BY",
    label: "Belarus",
    phone: "375",
    suggested: false,
  },
  {
    code: "BE",
    label: "Belgium",
    phone: "32",
    suggested: false,
  },
  {
    code: "BZ",
    label: "Belize",
    phone: "501",
    suggested: false,
  },
  {
    code: "BJ",
    label: "Benin",
    phone: "229",
    suggested: false,
  },
  {
    code: "BT",
    label: "Bhutan",
    phone: "975",
    suggested: false,
  },
  {
    code: "BO",
    label: "Bolivia",
    phone: "591",
    suggested: false,
  },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
    suggested: false,
  },
  {
    code: "BW",
    label: "Botswana",
    phone: "267",
    suggested: false,
  },
  {
    code: "BR",
    label: "Brazil",
    phone: "55",
    suggested: false,
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
    phone: "673",
    suggested: false,
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    suggested: false,
  },
  {
    code: "BF",
    label: "Burkina Faso",
    phone: "226",
    suggested: false,
  },
  {
    code: "BI",
    label: "Burundi",
    phone: "257",
    suggested: false,
  },
  {
    code: "KH",
    label: "Cambodia",
    phone: "855",
    suggested: false,
  },
  {
    code: "CM",
    label: "Cameroon",
    phone: "237",
    suggested: false,
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: false,
  },
  {
    code: "CV",
    label: "Cape Verde",
    phone: "238",
    suggested: false,
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    suggested: false,
  },
  {
    code: "TD",
    label: "Chad",
    phone: "235",
    suggested: false,
  },
  {
    code: "CL",
    label: "Chile",
    phone: "56",
    suggested: false,
  },
  {
    code: "CN",
    label: "China",
    phone: "86",
    suggested: false,
  },
  {
    code: "CO",
    label: "Colombia",
    phone: "57",
    suggested: false,
  },
  {
    code: "KM",
    label: "Comoros",
    phone: "269",
    suggested: false,
  },
  {
    code: "CG",
    label: "Congo (Republic of the)",
    phone: "242",
    suggested: false,
  },
  {
    code: "CD",
    label: "Congo (Democratic Republic of the)",
    phone: "243",
    suggested: false,
  },
  {
    code: "CR",
    label: "Costa Rica",
    phone: "506",
    suggested: false,
  },
  {
    code: "HR",
    label: "Croatia",
    phone: "385",
    suggested: false,
  },
  {
    code: "CU",
    label: "Cuba",
    phone: "53",
    suggested: false,
  },
  {
    code: "CY",
    label: "Cyprus",
    phone: "357",
    suggested: false,
  },
  {
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
    suggested: false,
  },
  {
    code: "DK",
    label: "Denmark",
    phone: "45",
    suggested: false,
  },
  {
    code: "DJ",
    label: "Djibouti",
    phone: "253",
    suggested: false,
  },
  {
    code: "DM",
    label: "Dominica",
    phone: "1-767",
    suggested: false,
  },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809, 1-829, 1-849",
    suggested: false,
  },
  {
    code: "EC",
    label: "Ecuador",
    phone: "593",
    suggested: false,
  },
  {
    code: "EG",
    label: "Egypt",
    phone: "20",
    suggested: false,
  },
  {
    code: "SV",
    label: "El Salvador",
    phone: "503",
    suggested: false,
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    phone: "240",
    suggested: false,
  },
  {
    code: "ER",
    label: "Eritrea",
    phone: "291",
    suggested: false,
  },
  {
    code: "EE",
    label: "Estonia",
    phone: "372",
    suggested: false,
  },
  {
    code: "ET",
    label: "Ethiopia",
    phone: "251",
    suggested: false,
  },
  {
    code: "FJ",
    label: "Fiji",
    phone: "679",
    suggested: false,
  },
  {
    code: "FI",
    label: "Finland",
    phone: "358",
    suggested: false,
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  {
    code: "GA",
    label: "Gabon",
    phone: "241",
    suggested: false,
  },
  {
    code: "GM",
    label: "Gambia",
    phone: "220",
    suggested: false,
  },
  {
    code: "GE",
    label: "Georgia",
    phone: "995",
    suggested: false,
  },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: false,
  },
  {
    code: "GH",
    label: "Ghana",
    phone: "233",
    suggested: false,
  },
  {
    code: "GR",
    label: "Greece",
    phone: "30",
    suggested: false,
  },
  {
    code: "GD",
    label: "Grenada",
    phone: "1-473",
    suggested: false,
  },
  {
    code: "GT",
    label: "Guatemala",
    phone: "502",
    suggested: false,
  },
  {
    code: "GN",
    label: "Guinea",
    phone: "224",
    suggested: false,
  },
  {
    code: "GW",
    label: "Guinea-Bissau",
    phone: "245",
    suggested: false,
  },
  {
    code: "GY",
    label: "Guyana",
    phone: "592",
    suggested: false,
  },
  {
    code: "HT",
    label: "Haiti",
    phone: "509",
    suggested: false,
  },
  {
    code: "HN",
    label: "Honduras",
    phone: "504",
    suggested: false,
  },
  {
    code: "HU",
    label: "Hungary",
    phone: "36",
    suggested: false,
  },
  {
    code: "IS",
    label: "Iceland",
    phone: "354",
    suggested: false,
  },
  {
    code: "IN",
    label: "India",
    phone: "91",
    suggested: false,
  },
  {
    code: "ID",
    label: "Indonesia",
    phone: "62",
    suggested: false,
  },
  {
    code: "IR",
    label: "Iran",
    phone: "98",
    suggested: false,
  },
  {
    code: "IQ",
    label: "Iraq",
    phone: "964",
    suggested: false,
  },
  {
    code: "IE",
    label: "Ireland",
    phone: "353",
    suggested: false,
  },
  {
    code: "IL",
    label: "Israel",
    phone: "972",
    suggested: false,
  },
  {
    code: "IT",
    label: "Italy",
    phone: "39",
    suggested: false,
  },
  {
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
    suggested: false,
  },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: false,
  },
  {
    code: "JO",
    label: "Jordan",
    phone: "962",
    suggested: false,
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
    suggested: false,
  },
  {
    code: "KE",
    label: "Kenya",
    phone: "254",
    suggested: false,
  },
  {
    code: "KI",
    label: "Kiribati",
    phone: "686",
    suggested: false,
  },
  {
    code: "KR",
    label: "Korea (South)",
    phone: "82",
    suggested: false,
  },
  {
    code: "KP",
    label: "Korea (North)",
    phone: "850",
    suggested: false,
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    suggested: false,
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
    suggested: false,
  },
  {
    code: "LA",
    label: "Laos",
    phone: "856",
    suggested: false,
  },
  {
    code: "LV",
    label: "Latvia",
    phone: "371",
    suggested: false,
  },
  {
    code: "LB",
    label: "Lebanon",
    phone: "961",
    suggested: false,
  },
  {
    code: "LS",
    label: "Lesotho",
    phone: "266",
    suggested: false,
  },
  {
    code: "LR",
    label: "Liberia",
    phone: "231",
    suggested: false,
  },
  {
    code: "LY",
    label: "Libya",
    phone: "218",
    suggested: false,
  },
  {
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
    suggested: false,
  },
  {
    code: "LT",
    label: "Lithuania",
    phone: "370",
    suggested: false,
  },
  {
    code: "LU",
    label: "Luxembourg",
    phone: "352",
    suggested: false,
  },
  {
    code: "MK",
    label: "Macedonia (Republic of)",
    phone: "389",
    suggested: false,
  },
  {
    code: "MG",
    label: "Madagascar",
    phone: "261",
    suggested: false,
  },
  {
    code: "MW",
    label: "Malawi",
    phone: "265",
    suggested: false,
  },
  {
    code: "MY",
    label: "Malaysia",
    phone: "60",
    suggested: false,
  },
  {
    code: "MV",
    label: "Maldives",
    phone: "960",
    suggested: false,
  },
  {
    code: "ML",
    label: "Mali",
    phone: "223",
    suggested: false,
  },
  {
    code: "MT",
    label: "Malta",
    phone: "356",
    suggested: false,
  },
  {
    code: "MH",
    label: "Marshall Islands",
    phone: "692",
    suggested: false,
  },
  {
    code: "MR",
    label: "Mauritania",
    phone: "222",
    suggested: false,
  },
  {
    code: "MU",
    label: "Mauritius",
    phone: "230",
    suggested: false,
  },
  {
    code: "MX",
    label: "Mexico",
    phone: "52",
    suggested: false,
  },
  {
    code: "FM",
    label: "Micronesia (Federated States of)",
    phone: "691",
    suggested: false,
  },
  {
    code: "MD",
    label: "Moldova",
    phone: "373",
    suggested: false,
  },
  {
    code: "MC",
    label: "Monaco",
    phone: "377",
    suggested: false,
  },
  {
    code: "MN",
    label: "Mongolia",
    phone: "976",
    suggested: false,
  },
  {
    code: "ME",
    label: "Montenegro",
    phone: "382",
    suggested: false,
  },
  {
    code: "MA",
    label: "Morocco",
    phone: "212",
    suggested: false,
  },
  {
    code: "MZ",
    label: "Mozambique",
    phone: "258",
    suggested: false,
  },
  {
    code: "MM",
    label: "Myanmar",
    phone: "95",
    suggested: false,
  },
  {
    code: "NA",
    label: "Namibia",
    phone: "264",
    suggested: false,
  },
  {
    code: "NR",
    label: "Nauru",
    phone: "674",
    suggested: false,
  },
  {
    code: "NP",
    label: "Nepal",
    phone: "977",
    suggested: false,
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    suggested: false,
  },
  {
    code: "NZ",
    label: "New Zealand",
    phone: "64",
    suggested: false,
  },
  {
    code: "NI",
    label: "Nicaragua",
    phone: "505",
    suggested: false,
  },
  {
    code: "NE",
    label: "Niger",
    phone: "227",
    suggested: false,
  },
  {
    code: "NG",
    label: "Nigeria",
    phone: "234",
    suggested: false,
  },
  {
    code: "NO",
    label: "Norway",
    phone: "47",
    suggested: false,
  },
  {
    code: "OM",
    label: "Oman",
    phone: "968",
    suggested: false,
  },
  {
    code: "PK",
    label: "Pakistan",
    phone: "92",
    suggested: false,
  },
  {
    code: "PW",
    label: "Palau",
    phone: "680",
    suggested: false,
  },
  {
    code: "PA",
    label: "Panama",
    phone: "507",
    suggested: false,
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    phone: "675",
    suggested: false,
  },
  {
    code: "PY",
    label: "Paraguay",
    phone: "595",
    suggested: false,
  },
  {
    code: "PE",
    label: "Peru",
    phone: "51",
    suggested: false,
  },
  {
    code: "PH",
    label: "Philippines",
    phone: "63",
    suggested: false,
  },
  {
    code: "PL",
    label: "Poland",
    phone: "48",
    suggested: false,
  },
  {
    code: "PT",
    label: "Portugal",
    phone: "351",
    suggested: false,
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    suggested: false,
  },
  {
    code: "RO",
    label: "Romania",
    phone: "40",
    suggested: false,
  },
  {
    code: "RU",
    label: "Russia",
    phone: "7",
    suggested: false,
  },
  {
    code: "RW",
    label: "Rwanda",
    phone: "250",
    suggested: false,
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    suggested: false,
  },
  {
    code: "LC",
    label: "Saint Lucia",
    phone: "1-758",
    suggested: false,
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    suggested: false,
  },
  {
    code: "WS",
    label: "Samoa",
    phone: "685",
    suggested: false,
  },
  {
    code: "SM",
    label: "San Marino",
    phone: "378",
    suggested: false,
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
    suggested: false,
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    suggested: false,
  },
  {
    code: "SN",
    label: "Senegal",
    phone: "221",
    suggested: false,
  },
  {
    code: "RS",
    label: "Serbia",
    phone: "381",
    suggested: false,
  },
  {
    code: "SC",
    label: "Seychelles",
    phone: "248",
    suggested: false,
  },
  {
    code: "SL",
    label: "Sierra Leone",
    phone: "232",
    suggested: false,
  },
  {
    code: "SG",
    label: "Singapore",
    phone: "65",
    suggested: false,
  },
  {
    code: "SK",
    label: "Slovakia",
    phone: "421",
    suggested: false,
  },
  {
    code: "SI",
    label: "Slovenia",
    phone: "386",
    suggested: false,
  },
  {
    code: "SB",
    label: "Solomon Islands",
    phone: "677",
    suggested: false,
  },
  {
    code: "SO",
    label: "Somalia",
    phone: "252",
    suggested: false,
  },
  {
    code: "ZA",
    label: "South Africa",
    phone: "27",
    suggested: false,
  },
  {
    code: "SS",
    label: "South Sudan",
    phone: "211",
    suggested: false,
  },
  {
    code: "ES",
    label: "Spain",
    phone: "34",
    suggested: false,
  },
  {
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
    suggested: false,
  },
  {
    code: "SD",
    label: "Sudan",
    phone: "249",
    suggested: false,
  },
  {
    code: "SR",
    label: "Suriname",
    phone: "597",
    suggested: false,
  },
  {
    code: "SZ",
    label: "Swaziland",
    phone: "268",
    suggested: false,
  },
  {
    code: "SE",
    label: "Sweden",
    phone: "46",
    suggested: false,
  },
  {
    code: "CH",
    label: "Switzerland",
    phone: "41",
    suggested: false,
  },
  {
    code: "SY",
    label: "Syria",
    phone: "963",
    suggested: false,
  },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
    suggested: false,
  },
  {
    code: "TJ",
    label: "Tajikistan",
    phone: "992",
    suggested: false,
  },
  {
    code: "TZ",
    label: "Tanzania",
    phone: "255",
    suggested: false,
  },
  {
    code: "TH",
    label: "Thailand",
    phone: "66",
    suggested: false,
  },
  {
    code: "TL",
    label: "Timor-Leste",
    phone: "670",
    suggested: false,
  },
  {
    code: "TG",
    label: "Togo",
    phone: "228",
    suggested: false,
  },
  {
    code: "TO",
    label: "Tonga",
    phone: "676",
    suggested: false,
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
    suggested: false,
  },
  {
    code: "TN",
    label: "Tunisia",
    phone: "216",
    suggested: false,
  },
  {
    code: "TR",
    label: "Turkey",
    phone: "90",
    suggested: false,
  },
  {
    code: "TM",
    label: "Turkmenistan",
    phone: "993",
    suggested: false,
  },
  {
    code: "TV",
    label: "Tuvalu",
    phone: "688",
    suggested: false,
  },
  {
    code: "UG",
    label: "Uganda",
    phone: "256",
    suggested: false,
  },
  {
    code: "UA",
    label: "Ukraine",
    phone: "380",
    suggested: false,
  },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    suggested: false,
  },
  {
    code: "GB",
    label: "United Kingdom",
    phone: "44",
    suggested: false,
  },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: false,
  },
  {
    code: "UY",
    label: "Uruguay",
    phone: "598",
    suggested: false,
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    suggested: false,
  },
  {
    code: "VU",
    label: "Vanuatu",
    phone: "678",
    suggested: false,
  },
  {
    code: "VA",
    label: "Vatican City State (Holy See)",
    phone: "379",
    suggested: false,
  },
  {
    code: "VE",
    label: "Venezuela",
    phone: "58",
    suggested: false,
  },
  {
    code: "VN",
    label: "Vietnam",
    phone: "84",
    suggested: false,
  },
  {
    code: "YE",
    label: "Yemen",
    phone: "967",
    suggested: false,
  },
  {
    code: "ZM",
    label: "Zambia",
    phone: "260",
    suggested: false,
  },
  {
    code: "ZW",
    label: "Zimbabwe",
    phone: "263",
    suggested: false,
  },
];

export const PHONE_COUNTRIES = [
  {
    countryCode: "AF",
    label: "Afghanistan",
    code: "93",
    suggested: false,
  },
  {
    countryCode: "AL",
    label: "Albania",
    code: "355",
    suggested: false,
  },
  {
    countryCode: "DZ",
    label: "Algeria",
    code: "213",
    suggested: false,
  },
  {
    countryCode: "AD",
    label: "Andorra",
    code: "376",
    suggested: false,
  },
  {
    countryCode: "AO",
    label: "Angola",
    code: "244",
    suggested: false,
  },
  {
    countryCode: "AG",
    label: "Antigua and Barbuda",
    code: "1-268",
    suggested: false,
  },
  {
    countryCode: "AR",
    label: "Argentina",
    code: "54",
    suggested: false,
  },
  {
    countryCode: "AM",
    label: "Armenia",
    code: "374",
    suggested: false,
  },
  {
    countryCode: "AU",
    label: "Australia",
    code: "61",
    suggested: false,
  },
  {
    countryCode: "AT",
    label: "Austria",
    code: "43",
    suggested: false,
  },
  {
    countryCode: "AZ",
    label: "Azerbaijan",
    code: "994",
    suggested: false,
  },
  {
    countryCode: "BS",
    label: "Bahamas",
    code: "1-242",
    suggested: false,
  },
  {
    countryCode: "BH",
    label: "Bahrain",
    code: "973",
    suggested: false,
  },
  {
    countryCode: "BD",
    label: "Bangladesh",
    code: "880",
    suggested: false,
  },
  {
    countryCode: "BB",
    label: "Barbados",
    code: "1-246",
    suggested: false,
  },
  {
    countryCode: "BY",
    label: "Belarus",
    code: "375",
    suggested: false,
  },
  {
    countryCode: "BE",
    label: "Belgium",
    code: "32",
    suggested: false,
  },
  {
    countryCode: "BZ",
    label: "Belize",
    code: "501",
    suggested: false,
  },
  {
    countryCode: "BJ",
    label: "Benin",
    code: "229",
    suggested: false,
  },
  {
    countryCode: "BT",
    label: "Bhutan",
    code: "975",
    suggested: false,
  },
  {
    countryCode: "BO",
    label: "Bolivia",
    code: "591",
    suggested: false,
  },
  {
    countryCode: "BA",
    label: "Bosnia and Herzegovina",
    code: "387",
    suggested: false,
  },
  {
    countryCode: "BW",
    label: "Botswana",
    code: "267",
    suggested: false,
  },
  {
    countryCode: "BR",
    label: "Brazil",
    code: "55",
    suggested: false,
  },
  {
    countryCode: "BN",
    label: "Brunei Darussalam",
    code: "673",
    suggested: false,
  },
  {
    countryCode: "BG",
    label: "Bulgaria",
    code: "359",
    suggested: false,
  },
  {
    countryCode: "BF",
    label: "Burkina Faso",
    code: "226",
    suggested: false,
  },
  {
    countryCode: "BI",
    label: "Burundi",
    code: "257",
    suggested: false,
  },
  {
    countryCode: "KH",
    label: "Cambodia",
    code: "855",
    suggested: false,
  },
  {
    countryCode: "CM",
    label: "Cameroon",
    code: "237",
    suggested: false,
  },
  {
    countryCode: "CA",
    label: "Canada",
    code: "1",
    suggested: false,
  },
  {
    countryCode: "CV",
    label: "Cape Verde",
    code: "238",
    suggested: false,
  },
  {
    countryCode: "CF",
    label: "Central African Republic",
    code: "236",
    suggested: false,
  },
  {
    countryCode: "TD",
    label: "Chad",
    code: "235",
    suggested: false,
  },
  {
    countryCode: "CL",
    label: "Chile",
    code: "56",
    suggested: false,
  },
  {
    countryCode: "CN",
    label: "China",
    code: "86",
    suggested: false,
  },
  {
    countryCode: "CO",
    label: "Colombia",
    code: "57",
    suggested: false,
  },
  {
    countryCode: "KM",
    label: "Comoros",
    code: "269",
    suggested: false,
  },
  {
    countryCode: "CG",
    label: "Congo (Republic of the)",
    code: "242",
    suggested: false,
  },
  {
    countryCode: "CD",
    label: "Congo (Democratic Republic of the)",
    code: "243",
    suggested: false,
  },
  {
    countryCode: "CR",
    label: "Costa Rica",
    code: "506",
    suggested: false,
  },
  {
    countryCode: "HR",
    label: "Croatia",
    code: "385",
    suggested: false,
  },
  {
    countryCode: "CU",
    label: "Cuba",
    code: "53",
    suggested: false,
  },
  {
    countryCode: "CY",
    label: "Cyprus",
    code: "357",
    suggested: false,
  },
  {
    countryCode: "CZ",
    label: "Czech Republic",
    code: "420",
    suggested: false,
  },
  {
    countryCode: "DK",
    label: "Denmark",
    code: "45",
    suggested: false,
  },
  {
    countryCode: "DJ",
    label: "Djibouti",
    code: "253",
    suggested: false,
  },
  {
    countryCode: "DM",
    label: "Dominica",
    code: "1-767",
    suggested: false,
  },
  {
    countryCode: "DO",
    label: "Dominican Republic",
    code: "1-809, 1-829, 1-849",
    suggested: false,
  },
  {
    countryCode: "EC",
    label: "Ecuador",
    code: "593",
    suggested: false,
  },
  {
    countryCode: "EG",
    label: "Egypt",
    code: "20",
    suggested: false,
  },
  {
    countryCode: "SV",
    label: "El Salvador",
    code: "503",
    suggested: false,
  },
  {
    countryCode: "GQ",
    label: "Equatorial Guinea",
    code: "240",
    suggested: false,
  },
  {
    countryCode: "ER",
    label: "Eritrea",
    code: "291",
    suggested: false,
  },
  {
    countryCode: "EE",
    label: "Estonia",
    code: "372",
    suggested: false,
  },
  {
    countryCode: "ET",
    label: "Ethiopia",
    code: "251",
    suggested: false,
  },
  {
    countryCode: "FJ",
    label: "Fiji",
    code: "679",
    suggested: false,
  },
  {
    countryCode: "FI",
    label: "Finland",
    code: "358",
    suggested: false,
  },
  {
    countryCode: "FR",
    label: "France",
    code: "33",
    suggested: true,
  },
  {
    countryCode: "GA",
    label: "Gabon",
    code: "241",
    suggested: false,
  },
  {
    countryCode: "GM",
    label: "Gambia",
    code: "220",
    suggested: false,
  },
  {
    countryCode: "GE",
    label: "Georgia",
    code: "995",
    suggested: false,
  },
  {
    countryCode: "DE",
    label: "Germany",
    code: "49",
    suggested: false,
  },
  {
    countryCode: "GH",
    label: "Ghana",
    code: "233",
    suggested: false,
  },
  {
    countryCode: "GR",
    label: "Greece",
    code: "30",
    suggested: false,
  },
  {
    countryCode: "GD",
    label: "Grenada",
    code: "1-473",
    suggested: false,
  },
  {
    countryCode: "GT",
    label: "Guatemala",
    code: "502",
    suggested: false,
  },
  {
    countryCode: "GN",
    label: "Guinea",
    code: "224",
    suggested: false,
  },
  {
    countryCode: "GW",
    label: "Guinea-Bissau",
    code: "245",
    suggested: false,
  },
  {
    countryCode: "GY",
    label: "Guyana",
    code: "592",
    suggested: false,
  },
  {
    countryCode: "HT",
    label: "Haiti",
    code: "509",
    suggested: false,
  },
  {
    countryCode: "HN",
    label: "Honduras",
    code: "504",
    suggested: false,
  },
  {
    countryCode: "HU",
    label: "Hungary",
    code: "36",
    suggested: false,
  },
  {
    countryCode: "IS",
    label: "Iceland",
    code: "354",
    suggested: false,
  },
  {
    countryCode: "IN",
    label: "India",
    code: "91",
    suggested: false,
  },
  {
    countryCode: "ID",
    label: "Indonesia",
    code: "62",
    suggested: false,
  },
  {
    countryCode: "IR",
    label: "Iran (Islamic Republic of)",
    code: "98",
    suggested: false,
  },
  {
    countryCode: "IQ",
    label: "Iraq",
    code: "964",
    suggested: false,
  },
  {
    countryCode: "IE",
    label: "Ireland",
    code: "353",
    suggested: false,
  },
  {
    countryCode: "IL",
    label: "Israel",
    code: "972",
    suggested: false,
  },
  {
    countryCode: "IT",
    label: "Italy",
    code: "39",
    suggested: false,
  },
  {
    countryCode: "JM",
    label: "Jamaica",
    code: "1-876",
    suggested: false,
  },
  {
    countryCode: "JP",
    label: "Japan",
    code: "81",
    suggested: false,
  },
  {
    countryCode: "JO",
    label: "Jordan",
    code: "962",
    suggested: false,
  },
  {
    countryCode: "KZ",
    label: "Kazakhstan",
    code: "7",
    suggested: false,
  },
  {
    countryCode: "KE",
    label: "Kenya",
    code: "254",
    suggested: false,
  },
  {
    countryCode: "KI",
    label: "Kiribati",
    code: "686",
    suggested: false,
  },
  {
    countryCode: "KW",
    label: "Kuwait",
    code: "965",
    suggested: false,
  },
  {
    countryCode: "KG",
    label: "Kyrgyzstan",
    code: "996",
    suggested: false,
  },
  {
    countryCode: "LA",
    label: "Lao People's Democratic Republic",
    code: "856",
    suggested: false,
  },
  {
    countryCode: "LV",
    label: "Latvia",
    code: "371",
    suggested: false,
  },
  {
    countryCode: "LB",
    label: "Lebanon",
    code: "961",
    suggested: false,
  },
  {
    countryCode: "LS",
    label: "Lesotho",
    code: "266",
    suggested: false,
  },
  {
    countryCode: "LR",
    label: "Liberia",
    code: "231",
    suggested: false,
  },
  {
    countryCode: "LY",
    label: "Libya",
    code: "218",
    suggested: false,
  },
  {
    countryCode: "LI",
    label: "Liechtenstein",
    code: "423",
    suggested: false,
  },
  {
    countryCode: "LT",
    label: "Lithuania",
    code: "370",
    suggested: false,
  },
  {
    countryCode: "LU",
    label: "Luxembourg",
    code: "352",
    suggested: false,
  },
  {
    countryCode: "MG",
    label: "Madagascar",
    code: "261",
    suggested: false,
  },
  {
    countryCode: "MW",
    label: "Malawi",
    code: "265",
    suggested: false,
  },
  {
    countryCode: "MY",
    label: "Malaysia",
    code: "60",
    suggested: false,
  },
  {
    countryCode: "MV",
    label: "Maldives",
    code: "960",
    suggested: false,
  },
  {
    countryCode: "ML",
    label: "Mali",
    code: "223",
    suggested: false,
  },
  {
    countryCode: "MT",
    label: "Malta",
    code: "356",
    suggested: false,
  },
  {
    countryCode: "MH",
    label: "Marshall Islands",
    code: "692",
    suggested: false,
  },
  {
    countryCode: "MR",
    label: "Mauritania",
    code: "222",
    suggested: false,
  },
  {
    countryCode: "MU",
    label: "Mauritius",
    code: "230",
    suggested: false,
  },
  {
    countryCode: "MX",
    label: "Mexico",
    code: "52",
    suggested: false,
  },
  {
    countryCode: "FM",
    label: "Micronesia (Federated States of)",
    code: "691",
    suggested: false,
  },
  {
    countryCode: "MC",
    label: "Monaco",
    code: "377",
    suggested: false,
  },
  {
    countryCode: "MN",
    label: "Mongolia",
    code: "976",
    suggested: false,
  },
  {
    countryCode: "ME",
    label: "Montenegro",
    code: "382",
    suggested: false,
  },
  {
    countryCode: "MA",
    label: "Morocco",
    code: "212",
    suggested: false,
  },
  {
    countryCode: "MZ",
    label: "Mozambique",
    code: "258",
    suggested: false,
  },
  {
    countryCode: "MM",
    label: "Myanmar",
    code: "95",
    suggested: false,
  },
  {
    countryCode: "NA",
    label: "Namibia",
    code: "264",
    suggested: false,
  },
  {
    countryCode: "NR",
    label: "Nauru",
    code: "674",
    suggested: false,
  },
  {
    countryCode: "NP",
    label: "Nepal",
    code: "977",
    suggested: false,
  },
  {
    countryCode: "NL",
    label: "Netherlands",
    code: "31",
    suggested: false,
  },
  {
    countryCode: "NZ",
    label: "New Zealand",
    code: "64",
    suggested: false,
  },
  {
    countryCode: "NI",
    label: "Nicaragua",
    code: "505",
    suggested: false,
  },
  {
    countryCode: "NE",
    label: "Niger",
    code: "227",
    suggested: false,
  },
  {
    countryCode: "NG",
    label: "Nigeria",
    code: "234",
    suggested: false,
  },
  {
    countryCode: "NO",
    label: "Norway",
    code: "47",
    suggested: false,
  },
  {
    countryCode: "OM",
    label: "Oman",
    code: "968",
    suggested: false,
  },
  {
    countryCode: "PK",
    label: "Pakistan",
    code: "92",
    suggested: false,
  },
  {
    countryCode: "PW",
    label: "Palau",
    code: "680",
    suggested: false,
  },
  {
    countryCode: "PA",
    label: "Panama",
    code: "507",
    suggested: false,
  },
  {
    countryCode: "PG",
    label: "Papua New Guinea",
    code: "675",
    suggested: false,
  },
  {
    countryCode: "PY",
    label: "Paraguay",
    code: "595",
    suggested: false,
  },
  {
    countryCode: "PE",
    label: "Peru",
    code: "51",
    suggested: false,
  },
  {
    countryCode: "PH",
    label: "Philippines",
    code: "63",
    suggested: false,
  },
  {
    countryCode: "PL",
    label: "Poland",
    code: "48",
    suggested: false,
  },
  {
    countryCode: "PT",
    label: "Portugal",
    code: "351",
    suggested: false,
  },
  {
    countryCode: "QA",
    label: "Qatar",
    code: "974",
    suggested: false,
  },
  {
    countryCode: "KR",
    label: "Republic of Korea",
    code: "82",
    suggested: false,
  },
  {
    countryCode: "MD",
    label: "Republic of Moldova",
    code: "373",
    suggested: false,
  },
  {
    countryCode: "RO",
    label: "Romania",
    code: "40",
    suggested: false,
  },
  {
    countryCode: "RU",
    label: "Russian Federation",
    code: "7",
    suggested: false,
  },
  {
    countryCode: "RW",
    label: "Rwanda",
    code: "250",
    suggested: false,
  },
  {
    countryCode: "KN",
    label: "Saint Kitts and Nevis",
    code: "1-869",
    suggested: false,
  },
  {
    countryCode: "LC",
    label: "Saint Lucia",
    code: "1-758",
    suggested: false,
  },
  {
    countryCode: "VC",
    label: "Saint Vincent and the Grenadines",
    code: "1-784",
    suggested: false,
  },
  {
    countryCode: "WS",
    label: "Samoa",
    code: "685",
    suggested: false,
  },
  {
    countryCode: "SM",
    label: "San Marino",
    code: "378",
    suggested: false,
  },
  {
    countryCode: "ST",
    label: "Sao Tome and Principe",
    code: "239",
    suggested: false,
  },
  {
    countryCode: "SA",
    label: "Saudi Arabia",
    code: "966",
    suggested: false,
  },
  {
    countryCode: "SN",
    label: "Senegal",
    code: "221",
    suggested: false,
  },
  {
    countryCode: "RS",
    label: "Serbia",
    code: "381",
    suggested: false,
  },
  {
    countryCode: "SC",
    label: "Seychelles",
    code: "248",
    suggested: false,
  },
  {
    countryCode: "SL",
    label: "Sierra Leone",
    code: "232",
    suggested: false,
  },
  {
    countryCode: "SG",
    label: "Singapore",
    code: "65",
    suggested: false,
  },
  {
    countryCode: "SK",
    label: "Slovakia",
    code: "421",
    suggested: false,
  },
  {
    countryCode: "SI",
    label: "Slovenia",
    code: "386",
    suggested: false,
  },
  {
    countryCode: "SB",
    label: "Solomon Islands",
    code: "677",
    suggested: false,
  },
  {
    countryCode: "SO",
    label: "Somalia",
    code: "252",
    suggested: false,
  },
  {
    countryCode: "ZA",
    label: "South Africa",
    code: "27",
    suggested: false,
  },
  {
    countryCode: "SS",
    label: "South Sudan",
    code: "211",
    suggested: false,
  },
  {
    countryCode: "ES",
    label: "Spain",
    code: "34",
    suggested: false,
  },
  {
    countryCode: "LK",
    label: "Sri Lanka",
    code: "94",
    suggested: false,
  },
  {
    countryCode: "SD",
    label: "Sudan",
    code: "249",
    suggested: false,
  },
  {
    countryCode: "SR",
    label: "Suriname",
    code: "597",
    suggested: false,
  },
  {
    countryCode: "SZ",
    label: "Swaziland",
    code: "268",
    suggested: false,
  },
  {
    countryCode: "SE",
    label: "Sweden",
    code: "46",
    suggested: false,
  },
  {
    countryCode: "CH",
    label: "Switzerland",
    code: "41",
    suggested: false,
  },
  {
    countryCode: "SY",
    label: "Syrian Arab Republic",
    code: "963",
    suggested: false,
  },
  {
    countryCode: "TJ",
    label: "Tajikistan",
    code: "992",
    suggested: false,
  },
  {
    countryCode: "TH",
    label: "Thailand",
    code: "66",
    suggested: false,
  },
  {
    countryCode: "MK",
    label: "North Macedonia",
    code: "389",
    suggested: false,
  },
  {
    countryCode: "TL",
    label: "Timor-Leste",
    code: "670",
    suggested: false,
  },
  {
    countryCode: "TG",
    label: "Togo",
    code: "228",
    suggested: false,
  },
  {
    countryCode: "TO",
    label: "Tonga",
    code: "676",
    suggested: false,
  },
  {
    countryCode: "TT",
    label: "Trinidad and Tobago",
    code: "1-868",
    suggested: false,
  },
  {
    countryCode: "TN",
    label: "Tunisia",
    code: "216",
    suggested: false,
  },
  {
    countryCode: "TR",
    label: "Turkey",
    code: "90",
    suggested: false,
  },
  {
    countryCode: "TM",
    label: "Turkmenistan",
    code: "993",
    suggested: false,
  },
  {
    countryCode: "TV",
    label: "Tuvalu",
    code: "688",
    suggested: false,
  },
  {
    countryCode: "UG",
    label: "Uganda",
    code: "256",
    suggested: false,
  },
  {
    countryCode: "UA",
    label: "Ukraine",
    code: "380",
    suggested: false,
  },
  {
    countryCode: "AE",
    label: "United Arab Emirates",
    code: "971",
    suggested: false,
  },
  {
    countryCode: "GB",
    label: "United Kingdom",
    code: "44",
    suggested: false,
  },
  {
    countryCode: "TZ",
    label: "United Republic of Tanzania",
    code: "255",
    suggested: false,
  },
  {
    countryCode: "US",
    label: "United States",
    code: "1",
    suggested: false,
  },
  {
    countryCode: "UY",
    label: "Uruguay",
    code: "598",
    suggested: false,
  },
  {
    countryCode: "UZ",
    label: "Uzbekistan",
    code: "998",
    suggested: false,
  },
  {
    countryCode: "VU",
    label: "Vanuatu",
    code: "678",
    suggested: false,
  },
  {
    countryCode: "VE",
    label: "Venezuela (Bolivarian Republic of)",
    code: "58",
    suggested: false,
  },
  {
    countryCode: "VN",
    label: "Viet Nam",
    code: "84",
    suggested: false,
  },
  {
    countryCode: "YE",
    label: "Yemen",
    code: "967",
    suggested: false,
  },
  {
    countryCode: "ZM",
    label: "Zambia",
    code: "260",
    suggested: false,
  },
  {
    countryCode: "ZW",
    label: "Zimbabwe",
    code: "263",
    suggested: false,
  },
];

export const LANGUAGES = [
  {
    code: "FR",
    label: "French",
  },
  {
    code: "EN",
    label: "English",
  },
];

export const TEACHER_LANGUAGES = [
  { code: "FR", label: "French" },
  { code: "EN", label: "English" },
  { code: "ES", label: "Spanish" },
  { code: "IT", label: "Italian" },
  { code: "DE", label: "German" },
  { code: "AR", label: "Arabic" },
  { code: "TR", label: "Turkish" },
  { code: "HR", label: "Croatian" },
  { code: "MS", label: "Malay" },
  { code: "VI", label: "Vietnamese" },
  { code: "ZH", label: "Chinese (Mandarin)" },
  { code: "AF", label: "Afrikaans" },
  { code: "PL", label: "Polish" },
  { code: "UK", label: "Ukrainian" },
  { code: "RU", label: "Russian" },
  { code: "HI/UR", label: "Hindi/Urdu" },
  { code: "EL", label: "Greek" },
];

export const TEACHER_ACCENT = [
  { code: "BR", label: "British" },
  { code: "EB", label: "English/British" },
  { code: "SB", label: "Scottish/British" },
  { code: "WB", label: "Welsh/British" },
  { code: "IR", label: "Irish" },
  { code: "AM", label: "American" },
  { code: "IN", label: "Indian" },
  { code: "AU", label: "Aussie" },
  { code: "NZ", label: "Kiwi" },
  { code: "NE", label: "Neutral" },
  { code: "OT", label: "Other" },
];

export const TEACHER_GRADES = [
  { code: "1", label: "Grade 1" },
  { code: "2", label: "Grade 2" },
  { code: "3", label: "Grade 3" },
  { code: "4", label: "Grade 4" },
  { code: "5", label: "Grade 5" },
  { code: "6", label: "Grade 6" },
  { code: "7", label: "Grade 7" },
  { code: "8", label: "Grade 8" },
  { code: "9", label: "Grade 9" },
  { code: "10", label: "Grade 10" },
  { code: "11", label: "Grade 11" },
  { code: "12", label: "Grade 12" },
  { code: "13", label: "Grade 13" },
  { code: "14", label: "Grade 14" },
  { code: "15", label: "Grade 15" },
  { code: "16", label: "Grade 16" },
  { code: "17", label: "Grade 17" },
  { code: "18", label: "Grade 18" },
  { code: "19", label: "Grade 19" },
  { code: "20", label: "Grade 20" },
  { code: "21", label: "Grade 21" },
  { code: "22", label: "Grade 22" },
  { code: "23", label: "Grade 23" },
  { code: "24", label: "Grade 24" },
  { code: "25", label: "Grade 25" },
  { code: "26", label: "Grade 26" },
  { code: "27", label: "Grade 27" },
  { code: "28", label: "Grade 28" },
  { code: "29", label: "Grade 29" },
  { code: "30", label: "Grade 30" },
  { code: "31", label: "Grade 31" },
  { code: "32", label: "Grade 32" },
  { code: "33", label: "Grade 33" },
  { code: "34", label: "Grade 34" },
  { code: "35", label: "Grade 35" },
  { code: "36", label: "Grade 36" },
];

export const TEACHER_LEARNINGSTYLE = [
  "Audio",
  "Visual",
  "Spatial",
  "Verbal",

  { code: "AD", label: "Audio" },
];
{
  /*export const countryFormattingRules = [
    { code: '33', pattern: /(\d{1,2})(\d{2})(\d{2})(\d{2})(\d{2})/, format: '$1 $2 $3 $4 $5' }, // France
    { code: '92', pattern: /(\d{3})(\d{7})/, format: '$1 $2' }, // Pakistan
    { code: '1', pattern: /(\d{3})(\d{3})(\d{4})/, format: '($1) $2-$3' }, // United States
]; */
}

export const countryFormattingRules = [
  { code: "93", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Afghanistan
  { code: "355", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Albania
  { code: "213", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Algeria
  { code: "376", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Andorra
  { code: "244", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Angola
  { code: "1-268", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Antigua and Barbuda
  { code: "54", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Argentina
  { code: "374", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Armenia
  {
    code: "61",
    pattern: /(\d{4})(\d{3})(\d{3})/,
    format: "$1 $2 $3",
    maxDigits: 10,
  }, // Australia
  {
    code: "43",
    pattern: /(\d{4})(\d{3})(\d{4})/,
    format: "$1 $2 $3",
    maxDigits: 11,
  }, // Austria
  { code: "994", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Azerbaijan
  { code: "1-242", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bahamas
  { code: "973", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bahrain
  { code: "880", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bangladesh
  { code: "1-246", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Barbados
  { code: "375", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Belarus
  { code: "32", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Belgium
  { code: "501", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Belize
  { code: "229", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Benin
  { code: "975", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bhutan
  { code: "591", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bolivia
  { code: "387", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bosnia and Herzegovina
  { code: "267", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Botswana
  {
    code: "55",
    pattern: /(\d{2})(\d{5})(\d{4})/,
    format: "$1 $2 $3",
    maxDigits: 11,
  }, // Brazil
  { code: "673", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Brunei Darussalam
  { code: "359", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Bulgaria
  { code: "226", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Burkina Faso
  { code: "257", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Burundi
  { code: "855", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cambodia
  { code: "237", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cameroon
  { code: "1-345", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cayman Islands
  { code: "236", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Central African Republic
  { code: "235", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Chad
  {
    code: "56",
    pattern: /(\d{2})(\d{3})(\d{4})/,
    format: "$1 $2 $3",
    maxDigits: 10,
  }, // Chile
  { code: "86", pattern: /(\d{3})(\d{8})/, format: "$1 $2", maxDigits: 11 }, // China
  { code: "57", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Colombia
  { code: "269", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Comoros
  { code: "243", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Congo, Democratic Republic of the
  { code: "242", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Congo, Republic of the
  { code: "506", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Costa Rica
  { code: "225", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cote d'Ivoire
  { code: "385", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Croatia
  { code: "53", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cuba
  { code: "357", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Cyprus
  { code: "420", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Czech Republic
  {
    code: "45",
    pattern: /(\d{2})(\d{2})(\d{2})(\d{2})/,
    format: "$1 $2 $3 $4",
    maxDigits: 8,
  }, // Denmark
  { code: "253", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Djibouti
  { code: "1-767", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Dominica
  { code: "1-849", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Dominican Republic
  { code: "670", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // East Timor (Timor-Leste)
  { code: "593", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Ecuador
  { code: "20", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Egypt
  { code: "503", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // El Salvador
  { code: "240", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Equatorial Guinea
  { code: "291", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Eritrea
  { code: "372", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Estonia
  { code: "251", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Ethiopia
  { code: "500", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Falkland Islands
  { code: "298", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Faroe Islands
  { code: "679", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Fiji
  { code: "358", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Finland
  {
    code: "33",
    pattern: /(\d{1,2})(\d{2})(\d{2})(\d{2})(\d{2})/,
    format: "$1 $2 $3 $4 $5",
    maxDigits: 9,
  }, // France
  { code: "594", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // French Guiana
  { code: "689", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // French Polynesia
  { code: "241", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Gabon
  { code: "220", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Gambia
  { code: "995", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Georgia
  { code: "49", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Germany
  { code: "233", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Ghana
  { code: "350", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Gibraltar
  { code: "30", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Greece
  { code: "299", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Greenland
  { code: "1-473", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Grenada
  { code: "590", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guadeloupe
  { code: "1-671", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guam
  { code: "502", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guatemala
  { code: "224", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guinea
  { code: "245", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guinea-Bissau
  { code: "592", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Guyana
  { code: "509", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Haiti
  { code: "504", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Honduras
  { code: "852", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Hong Kong
  { code: "36", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Hungary
  { code: "354", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Iceland
  { code: "91", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // India
  { code: "62", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Indonesia
  { code: "98", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Iran
  { code: "964", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Iraq
  { code: "353", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Ireland
  { code: "972", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Israel
  { code: "39", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Italy
  { code: "1-876", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Jamaica
  { code: "81", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Japan
  { code: "962", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Jordan
  { code: "7", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Kazakhstan
  { code: "254", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Kenya
  { code: "686", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Kiribati
  { code: "850", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Korea, North
  { code: "82", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Korea, South
  { code: "965", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Kuwait
  { code: "996", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Kyrgyzstan
  { code: "856", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Laos
  { code: "371", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Latvia
  { code: "961", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Lebanon
  { code: "266", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Lesotho
  { code: "231", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Liberia
  { code: "218", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Libya
  { code: "423", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Liechtenstein
  { code: "370", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Lithuania
  { code: "352", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Luxembourg
  { code: "853", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Macau
  { code: "389", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Macedonia
  { code: "261", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Madagascar
  { code: "265", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Malawi
  { code: "60", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Malaysia
  { code: "960", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Maldives
  { code: "223", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mali
  { code: "356", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Malta
  { code: "692", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Marshall Islands
  { code: "596", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Martinique
  { code: "222", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mauritania
  { code: "230", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mauritius
  { code: "262", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mayotte
  { code: "52", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mexico
  { code: "691", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Micronesia, Federated States of
  { code: "373", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Moldova
  { code: "377", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Monaco
  { code: "976", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mongolia
  { code: "382", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Montenegro
  { code: "1-664", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Montserrat
  { code: "212", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Morocco
  { code: "258", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Mozambique
  { code: "95", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Myanmar (Burma)
  { code: "264", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Namibia
  { code: "674", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Nauru
  { code: "977", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Nepal
  { code: "31", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Netherlands
  { code: "599", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Netherlands Antilles
  { code: "687", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // New Caledonia
  { code: "64", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // New Zealand
  { code: "505", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Nicaragua
  { code: "227", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Niger
  { code: "234", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Nigeria
  { code: "683", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Niue
  { code: "672", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Norfolk Island
  { code: "1-670", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Northern Mariana Islands
  { code: "47", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Norway
  { code: "968", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Oman
  { code: "92", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Pakistan
  { code: "680", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Palau
  { code: "507", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Panama
  { code: "675", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Papua New Guinea
  { code: "595", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Paraguay
  { code: "51", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Peru
  { code: "63", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Philippines
  { code: "48", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Poland
  { code: "351", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Portugal
  { code: "1-787", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Puerto Rico
  { code: "974", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Qatar
  { code: "262", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Reunion
  { code: "40", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Romania
  { code: "7", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Russia
  { code: "250", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Rwanda
  { code: "590", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Barthelemy
  { code: "290", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Helena
  { code: "1-869", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Kitts and Nevis
  { code: "1-758", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Lucia
  { code: "590", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Martin
  { code: "508", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Pierre and Miquelon
  { code: "1-784", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saint Vincent and the Grenadines
  { code: "685", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Samoa
  { code: "378", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // San Marino
  { code: "239", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sao Tome and Principe
  { code: "966", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Saudi Arabia
  { code: "221", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Senegal
  { code: "381", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Serbia
  { code: "248", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Seychelles
  { code: "232", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sierra Leone
  { code: "65", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Singapore
  { code: "1-721", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sint Maarten
  { code: "421", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Slovakia
  { code: "386", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Slovenia
  { code: "677", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Solomon Islands
  { code: "252", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Somalia
  { code: "27", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // South Africa
  { code: "211", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // South Sudan
  { code: "34", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Spain
  { code: "94", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sri Lanka
  { code: "249", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sudan
  { code: "597", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Suriname
  { code: "47", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Svalbard and Jan Mayen
  { code: "268", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Swaziland
  { code: "46", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Sweden
  { code: "41", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Switzerland
  { code: "963", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Syria
  { code: "886", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Taiwan
  { code: "992", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tajikistan
  { code: "255", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tanzania
  { code: "66", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Thailand
  { code: "670", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Timor-Leste
  { code: "228", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Togo
  { code: "690", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tokelau
  { code: "676", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tonga
  { code: "1-868", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Trinidad and Tobago
  { code: "216", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tunisia
  { code: "90", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Turkey
  { code: "993", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Turkmenistan
  { code: "1-649", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Turks and Caicos Islands
  { code: "688", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Tuvalu
  { code: "256", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Uganda
  { code: "380", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Ukraine
  { code: "971", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // United Arab Emirates
  { code: "44", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // United Kingdom
  { code: "1", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // United States
  { code: "598", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Uruguay
  { code: "998", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Uzbekistan
  { code: "678", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Vanuatu
  { code: "379", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Vatican City
  { code: "58", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Venezuela
  { code: "84", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Vietnam
  { code: "681", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Wallis and Futuna
  { code: "212", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Western Sahara
  { code: "967", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Yemen
  { code: "260", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Zambia
  { code: "263", pattern: /(\d{3})(\d{7})/, format: "$1 $2", maxDigits: 10 }, // Zimbabwe
];

export const timingSlots = [
  { label: "00:30", value: 30 },
  { label: "01:00", value: 60 },
  { label: "01:30", value: 90 },
  { label: "02:00", value: 120 },
  { label: "02:30", value: 150 },
  { label: "03:00", value: 180 },
  { label: "03:30", value: 210 },
  { label: "04:00", value: 240 },
  { label: "04:30", value: 270 },
  { label: "05:00", value: 300 },
  { label: "05:30", value: 330 },
  { label: "06:00", value: 360 },
  { label: "06:30", value: 390 },
  { label: "07:00", value: 420 },
  { label: "07:30", value: 450 },
  { label: "08:00", value: 480 },
];

export const timingsByDuration = ({ duration }) => {
  if (!duration) return [];
  const timings = [];
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const date = new Date(currentDate);
  date.setMinutes(date.getMinutes() + duration);
  let label1 = moment(currentDate).format("HH:mm");
  while (date.getDate() === currentDate.getDate()) {
    let label2 = moment(date).format("HH:mm");
    const obj = { label: `${label1} - ${label2}`, value: label1 };
    timings.push(obj);
    label1 = label2;
    const tmpDate1 = new Date(date);
    date.setMinutes(date.getMinutes() + duration);
    if (tmpDate1.getDate() !== date.getDate()) {
      const tmpDate2 = new Date(date);
      tmpDate2.setMinutes(tmpDate2.getMinutes() - duration);
      tmpDate2.setMinutes(59);
      tmpDate2.setHours(23);
      if (
        (tmpDate2.getTime() - tmpDate1.getTime()) / (60 * 1000) ===
        duration - 1
      ) {
        const obj = { label: `${label1} - 00:00`, value: label1 };
        timings.push(obj);
      }
    }
  }
  return timings;
};

export const timings = [
  { label: "00:00 - 00:30", value: "00:00" },
  { label: "00:30 - 01:00", value: "00:30" },
  { label: "01:00 - 01:30", value: "01:00" },
  { label: "01:30 - 02:00", value: "01:30" },
  { label: "02:00 - 02:30", value: "02:00" },
  { label: "02:30 - 03:00", value: "02:30" },
  { label: "03:00 - 03:30", value: "03:00" },
  { label: "03:30 - 04:00", value: "03:30" },
  { label: "04:00 - 04:30", value: "04:00" },
  { label: "04:30 - 05:00", value: "04:30" },
  { label: "05:00 - 05:30", value: "05:00" },
  { label: "05:30 - 06:00", value: "05:30" },
  { label: "06:00 - 06:30", value: "06:00" },
  { label: "06:30 - 07:00", value: "06:30" },
  { label: "07:00 - 07:30", value: "07:00" },
  { label: "07:30 - 08:00", value: "07:30" },
  { label: "08:00 - 08:30", value: "08:00" },
  { label: "08:30 - 09:00", value: "08:30" },
  { label: "09:00 - 09:30", value: "09:00" },
  { label: "09:30 - 10:00", value: "09:30" },
  { label: "10:00 - 10:30", value: "10:00" },
  { label: "10:30 - 11:00", value: "10:30" },
  { label: "11:00 - 11:30", value: "11:00" },
  { label: "11:30 - 12:00", value: "11:30" },
  { label: "12:00 - 12:30", value: "12:00" },
  { label: "12:30 - 13:00", value: "12:30" },
  { label: "13:00 - 13:30", value: "13:00" },
  { label: "13:30 - 14:00", value: "13:30" },
  { label: "14:00 - 14:30", value: "14:00" },
  { label: "14:30 - 15:00", value: "14:30" },
  { label: "15:00 - 15:30", value: "15:00" },
  { label: "15:30 - 16:00", value: "15:30" },
  { label: "16:00 - 16:30", value: "16:00" },
  { label: "16:30 - 17:00", value: "16:30" },
  { label: "17:00 - 17:30", value: "17:00" },
  { label: "17:30 - 18:00", value: "17:30" },
  { label: "18:00 - 18:30", value: "18:00" },
  { label: "18:30 - 19:00", value: "18:30" },
  { label: "19:00 - 19:30", value: "19:00" },
  { label: "19:30 - 20:00", value: "19:30" },
  { label: "20:00 - 20:30", value: "20:00" },
  { label: "20:30 - 21:00", value: "20:30" },
  { label: "21:00 - 21:30", value: "21:00" },
  { label: "21:30 - 22:00", value: "21:30" },
  { label: "22:00 - 22:30", value: "22:00" },
  { label: "22:30 - 23:00", value: "22:30" },
  { label: "23:00 - 23:30", value: "23:00" },
  { label: "23:30 - 00:00", value: "23:30" },
];

export const timings2 = [
  { label: "00:00", value: "00:00" },
  { label: "00:30", value: "00:30" },
  { label: "01:00", value: "01:00" },
  { label: "01:30", value: "01:30" },
  { label: "02:00", value: "02:00" },
  { label: "02:30", value: "02:30" },
  { label: "03:00", value: "03:00" },
  { label: "03:30", value: "03:30" },
  { label: "04:00", value: "04:00" },
  { label: "04:30", value: "04:30" },
  { label: "05:00", value: "05:00" },
  { label: "05:30", value: "05:30" },
  { label: "06:00", value: "06:00" },
  { label: "06:30", value: "06:30" },
  { label: "07:00", value: "07:00" },
  { label: "07:30", value: "07:30" },
  { label: "08:00", value: "08:00" },
  { label: "08:30", value: "08:30" },
  { label: "09:00", value: "09:00" },
  { label: "09:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
];

export const actualLevels = [
  "Nursery 1",
  "Nursery 2",
  "Nursery 3",
  "Pre A1 - CHILD 1",
  "Pre A1 - CHILD 2",
  "Pre A1 - TEEN 1",
  "Pre A1 - TEEN 2",
  "Pre A1 - ADULT 1",
  "Pre A1 - ADULT 2",
  "A1",
  "A2",
  "B1",
  "B2",
  "C1",
  "C2",
];

export const HOBBIESOld = [
  { key: "art", value: "Art" },
  { key: "sing", value: "Sing" },
  { key: "games", value: "Video Games" },
  { key: "dance", value: "Dance" },
  { key: "music", value: "Music" },
  { key: "reading", value: "Reading" },
  { key: "team-sport", value: "Team sport" },
  { key: "sport", value: "Individual sport" },
  { key: "other", value: "Other" },
];

export const HOBBIES = [
  {
    key: "painting-drawing",
    value: "Painting & Drawing (Watercolor, graffiti, sketching, manga)",
  },
  {
    key: "photography-video",
    value: "Photography & Video 📸 (Portrait, landscape, editing, vlogging)",
  },
  {
    key: "theater-improv",
    value: "Theater & Improvisation 🎭 (Stand-up comedy)",
  },
  { key: "reading-writing", value: "Reading & Writing 📚 (Novels)" },
  {
    key: "board-strategy-games",
    value: "Board & Strategy Games 🎲 (Chess, role-playing games)",
  },
  {
    key: "video-games-esport",
    value: "Video Games & eSports 🎮 (FPS, MMORPG, strategy, speedrunning)",
  },
  {
    key: "team-sports",
    value: "Team Sports ⚽ (Football, basketball, volleyball, rugby)",
  },
  {
    key: "individual-sports",
    value: "Individual Sports 🏋️‍♂️ (Tennis, cycling, boxing, swimming)",
  },
  {
    key: "extreme-sports",
    value: "Extreme Sports 🏂 (Skateboarding, rock climbing, skydiving)",
  },
  {
    key: "hiking-nature",
    value: "Hiking & Nature 🌿 (Trekking, camping, wilderness survival)",
  },
  {
    key: "gardening",
    value: "Gardening & Permaculture 🌱 (Vegetable garden, botany, bonsai)",
  },
  {
    key: "cooking",
    value: "Cooking & Gastronomy 🍳 (Baking, barbecue, mixology)",
  },
  {
    key: "traveling",
    value: "Travel & Exploration ✈️ (Road trips, tourism, adventure)",
  },
  {
    key: "diy",
    value: "DIY & Handicrafts 🛠️ (Electronics, woodworking, sewing)",
  },
  {
    key: "technology",
    value: "Technology & Digital 💻 (Programming, AI, robotics)",
  },
  { key: "astronomy", value: "Astronomy & Science 🔭 (Stargazing, physics)" },
  {
    key: "automobile-mechanics",
    value: "Automobiles & Mechanics 🚗 (Tuning, karting, motorcycles)",
  },
  {
    key: "fishing-water-sports",
    value: "Fishing & Water Sports 🎣 (Surfing, canoeing, diving)",
  },
  {
    key: "modeling-collection",
    value: "Modeling & Collecting 🚂 (Model kits, figurines, Lego)",
  },
  {
    key: "volunteering",
    value:
      "Volunteering & Social Work 🤝 (Humanitarian aid, environmental activism)",
  },

  {
    key: "fashion-beauty",
    value: "Fashion & Beauty 👗 (Makeup, sewing, fashion design)",
  },
];

export const objectiveLevel = [
  "Nursery 1",
  "Nursery 2",
  "Nursery 3",
  "Pre A1 - CHILD 1",
  "Pre A1 - CHILD 2",
  "Pre A1 - TEEN 1",
  "Pre A1 - TEEN 2",
  "Pre A1 - ADULT 1",
  "Pre A1 - ADULT 2",
  "A1",
  "A2",
  "B1",
  "B2",
  "C1",
  "C2",
];

export const learningStyle = ["Audio", "Visual", "Spatial", "Verbal"];

export const programFrequency = [
  "1 time/week",
  "2 times/week",
  "3 times/week",
  " 4 times/week",
  " 5 times/week",
  "Every day",
];

export const PROJECTS = [
  { key: "general-english", value: "General English" },
  { key: "first-step", value: "First Step" },
  { key: "exam-preparation", value: "Exam Preparation" },
  { key: "confidence", value: "Priority on self-confidence" },
  { key: "communication", value: "Priority on communication" },
  { key: "pronunciation", value: "Priority on pronunciation" },
  { key: "writing", value: "Priority on writing" },
  { key: "sound", value: "Deepen your sound bilingualism" },
  { key: "forFun", value: "Just for fun" },
];

export const EDUCATION_NEEDS = [
  { key: "hpi-diagnosed", value: "HPI diagnosed" },
  { key: "hpi-suspicion", value: "HPI suspicion" },
  { key: "dys-diagnosed", value: "DYS diagnosed" },
  { key: "dys-suspicion", value: "DYS suspicion" },
  { key: "tdah-diagnosed", value: "TDAH diagnosed" },
  { key: "tdah-suspicion", value: "TDAH suspicion" },
  { key: "tsa-diagnosed", value: "TSA diagnosed" },
  { key: "tsa-suspicion", value: "TSA suspicion" },
  { key: "none", value: "None" },
];
