import React, { useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ListSubheader } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import DDFile from "../../../../component/DDFile";
import FileBase64 from "react-file-base64";
import { HOBBIES, learningStyle } from "../../../../utils/constants/appActions";
import { useAppMutation } from "../../../../services";
import adminServices from "../../../../services/admin-services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step3 = ({ hide, setStepsData, stepsData, handleNext }) => {
  const {
    mutate: loadSkills,
    isError: isSkillError,
    data: dataSkills,
    isSuccess: isSkillSuccess,
    isLoading: isSkillLoading,
  } = useAppMutation(adminServices.loadSkills);

  useEffect(() => {
    if (isSkillSuccess) {
      console.log("dataSkills----", dataSkills);
      setSkills(dataSkills);
    }
  }, [isSkillError, isSkillSuccess]);

  const [skills, setSkills] = React.useState([]);

  const [values, setValues] = React.useState({
    teachingSpecialities: [],
    ageGroupPreferences: [],
    teachingDiploma: "",
    learningStyles: [],
    hobbies: [],
    summary: "",
    webDesc: "",
    skills: [],
  });
  const [errMsg, setErrMsg] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState();
  const [hobbies, setHobbies] = React.useState([]);
  const ref = React.useRef();

  useEffect(() => {
    if (stepsData) {
      setValues({ ...values, ...stepsData });
      loadSkills();
    }

    const translatedHobbies = HOBBIES.map((hobby) => ({
      key: hobby.key,
      value: t(hobby.key),
    }));
    setHobbies(translatedHobbies);
  }, [stepsData]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitStep3 = async () => {
    setErrMsg("");

    const {
      teachingSpecialities,
      ageGroupPreferences,
      teachingDiploma,
      base64,
      selectedFile,
      learningStyles,
      hobbies,
      summary,
      webDesc,
      skills,
    } = values;

    setStepsData({
      ...stepsData,
      teachingSpecialities,
      ageGroupPreferences,
      teachingDiploma,
      base64,
      learningStyles,
      hobbies,
      summary,
      webDesc,
      skills,
    });
    handleNext(4);
  };

  const backToStep2 = async () => {
    handleNext(2);
  };

  const handleChangeMultiple = (name) => (event) => {
    const {
      target: { value },
    } = event;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));
  };
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Div sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <FormControl
          sx={{
            width: "100%",
            mt: 1,
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label={t("teacherForm.diploma")}
            value={values.teachingDiploma}
            name="comments"
            onChange={handleChange("teachingDiploma")}
          />
        </FormControl>

        <FormControl
          sx={{
            width: "100%",
            mt: 2,
          }}
        >
          <Typography variant={"h4"}>{t("teacherForm.diploma")}</Typography>
          <FileBase64
            ref={ref}
            maxSize={1000000}
            multiple={false}
            onDone={async (file) => {
              console.log(file, ref);
              if (file.file.size > 1000000) {
                setErrMsg(t("common.sizeErr"));
                setTimeout(() => {
                  setErrMsg("");
                }, 3000);
                ref.current.value = "";
                setValues({ ...values, base64: undefined });
              } else {
                setValues({ ...values, base64: file.base64 });
              }
            }}
          />
          {values.base64 && <DDFile base64={values.base64} />}
          {!values.base64 && values?.teachingDiplomaFiles?.length > 0 && (
            <DDFile path={values?.teachingDiplomaFiles[0]} />
          )}
        </FormControl>

        <FormControl
          sx={{ width: { xs: "100%", sm: "100%", xl: "100%" }, mt: 1.5 }}
        >
          <Autocomplete
            style={{ margin: "10px 0" }}
            multiple
            id="tags-outlined"
            options={skills}
            value={values.skills ? values.skills : []}
            freeSolo
            autoSelect
            onChange={(e, newValues) => {
              const val = e.target.value;
              console.log(newValues, val);

              if (e.target.value) {
                let { skills: cats } = values;

                const index = cats.findIndex(
                  (ii) => ii.trim().toLowerCase() === val.trim().toLowerCase(),
                );
                if (index === -1) {
                  cats = [...cats, val.trim().toLowerCase()];
                  setValues({ ...values, skills: cats });
                }
              } else {
                setValues({ ...values, skills: newValues });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("common.skills")}
                placeholder={t("common.skills")}
                value={values.skills}
              />
            )}
          />
        </FormControl>

        {/*<FormControl
          sx={{
            width: "100%",
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.speciality")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.teachingSpecialities}
            onChange={handleChange("teachingSpecialities")}
            label={t("teacherForm.speciality")}
          >
            <MenuItem value="conversation">Conversation practice</MenuItem>
            <MenuItem value="vocabulary">Vocabulary building</MenuItem>
            <MenuItem value="writing">Writing skills</MenuItem>
            <MenuItem value="reading">Reading comprehension</MenuItem>
            <MenuItem value="listening">Listening comprehension</MenuItem>
            <MenuItem value="business">Business English</MenuItem>
            <MenuItem value="academic">Academic English</MenuItem>
            <MenuItem value="purpose">
              English for specific purposes (e.g., tourism, hospitality,
              healthcare)
            </MenuItem>
            <MenuItem value="awareness">
              Cultural awareness and intercultural communication
            </MenuItem>
            <MenuItem value="exam">
              Exam preparation (e.g., TOEFL, IELTS, Cambridge exams)
            </MenuItem>
          </Select>
        </FormControl>*/}

        <FormControl
          sx={{
            width: "100%",
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.speciality")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.teachingSpecialities}
            onChange={handleChange("teachingSpecialities")}
            label={t("teacherForm.speciality")}
          >
            {/* 1. Sciences, Technology & Engineering */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              1. Sciences, Technologie et Ingénierie 🔬⚙️💡
            </ListSubheader>
            <ListSubheader sx={{ color: "#000" }}>
              Métiers scientifiques
            </ListSubheader>
            <MenuItem value="biologist">
              Chercheur en biologie, physique, chimie, mathématiques
            </MenuItem>
            <MenuItem value="astronomer">Astronome</MenuItem>
            <MenuItem value="geneticist">Généticien</MenuItem>
            <MenuItem value="climatologist">Climatologue</MenuItem>
            <MenuItem value="geologist">Géologue</MenuItem>
            <MenuItem value="oceanographer">Océanographe</MenuItem>

            <ListSubheader sx={{ color: "#000" }}>
              Métiers de l’ingénierie
            </ListSubheader>
            <MenuItem value="aeronautical_engineer">
              Ingénieur aéronautique
            </MenuItem>
            <MenuItem value="automobile_engineer">
              Ingénieur automobile
            </MenuItem>
            <MenuItem value="ai_engineer">
              Ingénieur en intelligence artificielle
            </MenuItem>
            <MenuItem value="civil_engineer">Ingénieur civil</MenuItem>
            <MenuItem value="robotics_engineer">
              Ingénieur en robotique
            </MenuItem>

            <MenuItem value="energy_engineer">
              Ingénieur en génie énergétique
            </MenuItem>
            <MenuItem value="nanotech_engineer">
              Ingénieur en nanotechnologies
            </MenuItem>
            <MenuItem value="cybersecurity_engineer">
              Ingénieur en cybersécurité
            </MenuItem>
            <MenuItem value="robotics_engineer">
              Ingénieur en robotique
            </MenuItem>

            <ListSubheader sx={{ color: "#000" }}>
              Métiers de l’industrie et de l’énergie
            </ListSubheader>
            <MenuItem value="renewable_energy_technician">
              Technicien en énergies renouvelables
            </MenuItem>
            <MenuItem value="nuclear_plant_manager">
              Responsable de centrale nucléaire
            </MenuItem>
            <MenuItem value="climate_engineering_technician">
              Technicien en génie climatique
            </MenuItem>
            <MenuItem value="petrochemical_engineer">
              Ingénieur en pétrochimie
            </MenuItem>
            <MenuItem value="hydraulic_engineer">
              Ingénieur en hydraulique
            </MenuItem>

            {/* 2. IT & Digital Fields */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              2. Informatique et Numérique
            </ListSubheader>
            <MenuItem value="web-developer">Développeur web / mobile</MenuItem>
            <MenuItem value="cybersecurity-engineer">
              Ingénieur en cybersécurité
            </MenuItem>
            <MenuItem value="ai-expert">
              Expert en intelligence artificielle
            </MenuItem>
            <MenuItem value="game-designer">
              Game designer (créateur de jeux vidéo)
            </MenuItem>
            <MenuItem value="network-admin">Administrateur réseau</MenuItem>
            <MenuItem value="data-scientist">Data scientist</MenuItem>
            <MenuItem value="ethical-hacker">Ethical hacker</MenuItem>
            <MenuItem value="uiux-designer">UX/UI designer</MenuItem>
            <MenuItem value="cloud-computing">
              Ingénieur cloud computing
            </MenuItem>

            {/* 3. Health & Social Professions */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              3. Métiers de la Santé et du Social 🏥🩺
            </ListSubheader>
            <ListSubheader sx={{ color: "#000" }}>
              Métiers médicaux
            </ListSubheader>
            <MenuItem value="general_practitioner">
              Médecin généraliste
            </MenuItem>
            <MenuItem value="surgeon">
              Chirurgien (cardiaque, neurologique, esthétique, etc.)
            </MenuItem>
            <MenuItem value="dentist">Dentiste</MenuItem>
            <MenuItem value="pharmacist">Pharmacien</MenuItem>
            <MenuItem value="ophthalmologist">Ophtalmologiste</MenuItem>
            <MenuItem value="radiologist">Radiologue</MenuItem>
            <MenuItem value="psychiatrist">Psychiatre</MenuItem>

            {/* Métiers paramédicaux */}
            <ListSubheader sx={{ color: "#000" }}>
              Métiers paramédicaux
            </ListSubheader>
            <MenuItem value="nurse">Infirmier</MenuItem>
            <MenuItem value="physiotherapist">Kinésithérapeute</MenuItem>
            <MenuItem value="speech_therapist">Orthophoniste</MenuItem>
            <MenuItem value="osteopath">Ostéopathe</MenuItem>
            <MenuItem value="paramedic">Ambulancier</MenuItem>

            {/* Métiers du social */}
            <ListSubheader sx={{ color: "#000" }}>
              Métiers du social
            </ListSubheader>
            <MenuItem value="social_assistant">Assistant social</MenuItem>
            <MenuItem value="specialized_educator">
              Éducateur spécialisé
            </MenuItem>
            <MenuItem value="career_advisor">
              Conseiller en insertion professionnelle
            </MenuItem>

            {/* 4. Economy, Finance & Management */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              4. Économie, Finance et Gestion 📊💰
            </ListSubheader>
            <MenuItem value="financial_analyst">Analyste financier</MenuItem>
            <MenuItem value="banker">Banquier</MenuItem>
            <MenuItem value="accountant">Comptable</MenuItem>
            <MenuItem value="risk_management_expert">
              Expert en gestion des risques
            </MenuItem>
            <MenuItem value="trader">Trader</MenuItem>
            <MenuItem value="tax_expert">Fiscaliste</MenuItem>
            <MenuItem value="financial_auditor">Auditeur financier</MenuItem>
            <MenuItem value="economist">Économiste</MenuItem>

            {/* 5. Law, Politics & International Relations */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              5. Droit, Politique et Relations Internationales ⚖️🏛️
            </ListSubheader>
            <MenuItem value="lawyer">Avocat</MenuItem>
            <MenuItem value="judge">Juge</MenuItem>
            <MenuItem value="notary">Notaire</MenuItem>
            <MenuItem value="bailiff">Huissier de justice</MenuItem>
            <MenuItem value="deputy_senator">Député, sénateur</MenuItem>
            <MenuItem value="ambassador">Ambassadeur</MenuItem>
            <MenuItem value="international_relations_consultant">
              Consultant en relations internationales
            </MenuItem>
            <MenuItem value="geopolitical_analyst">
              Analyste géopolitique
            </MenuItem>

            {/* 6. Communication, Marketing & Journalism */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              6. Communication, Marketing et Journalisme 📰🎤
            </ListSubheader>
            <MenuItem value="journalist">
              Journaliste (radio, presse écrite, télé, web)
            </MenuItem>
            <MenuItem value="web_writer">Rédacteur web</MenuItem>
            <MenuItem value="press_officer">Attaché de presse</MenuItem>
            <MenuItem value="community_manager">Community manager</MenuItem>
            <MenuItem value="advertiser">Publicitaire</MenuItem>
            <MenuItem value="digital_marketing_manager">
              Responsable marketing digital
            </MenuItem>

            {/* 7. Arts, Culture & Design */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              7. Art, Culture et Design 🎨🎭
            </ListSubheader>
            <MenuItem value="painter">Artiste peintre</MenuItem>
            <MenuItem value="graphic_designer">Designer graphique</MenuItem>
            <MenuItem value="interior_designer">
              Architecte d’intérieur
            </MenuItem>
            <MenuItem value="screenwriter">Scénariste</MenuItem>
            <MenuItem value="photographer">Photographe</MenuItem>
            <MenuItem value="videographer">Vidéaste</MenuItem>
            <MenuItem value="actor">Acteur</MenuItem>

            {/* 8. Hospitality, Catering & Tourism */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              8. Hôtellerie, Restauration et Tourisme 🍽️✈️
            </ListSubheader>
            <MenuItem value="chef_cook">Chef cuisinier</MenuItem>
            <MenuItem value="sommelier">Sommelier</MenuItem>
            <MenuItem value="hotel_manager">Directeur d’hôtel</MenuItem>
            <MenuItem value="tour_guide">Guide touristique</MenuItem>
            <MenuItem value="steward_airhostess">
              Steward / Hôtesse de l’air
            </MenuItem>

            {/* 9. Sports & Physical Activities */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              9. Sport et Activité Physique ⚽🏀
            </ListSubheader>
            <MenuItem value="football_basket_tennis">
              Footballeur, basketteur, tennisman, etc.
            </MenuItem>
            <MenuItem value="sport_coach">Coach sportif</MenuItem>
            <MenuItem value="sports_physio">Kinésithérapeute du sport</MenuItem>
            <MenuItem value="sports_trainer">Entraîneur sportif</MenuItem>
            <MenuItem value="eps_teacher">Professeur d’EPS</MenuItem>

            {/* 10. Agriculture, Environment & Ecology */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              10. Agriculture, Environnement et Écologie 🌱🌍
            </ListSubheader>
            <MenuItem value="farmer">Agriculteur</MenuItem>
            <MenuItem value="farmer_breeder">Éleveur</MenuItem>
            <MenuItem value="agronomist">Ingénieur agronome</MenuItem>
            <MenuItem value="sustainable_development">
              Spécialiste en développement durable
            </MenuItem>
            <MenuItem value="waste_management_expert">
              Expert en gestion des déchets
            </MenuItem>

            {/* 11. Transport, Logistics & Aeronautics */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              11. Transport, Logistique et Aéronautique 🚗🚢✈️
            </ListSubheader>
            <MenuItem value="airline_pilot">Pilote de ligne</MenuItem>
            <MenuItem value="train_driver">Conducteur de train</MenuItem>
            <MenuItem value="logistician">Logisticien</MenuItem>
            <MenuItem value="air_traffic_controller">
              Contrôleur aérien
            </MenuItem>
            <MenuItem value="truck_driver">Chauffeur de poids lourd</MenuItem>

            {/* 12. Manual & Artisan Professions */}
            <ListSubheader sx={{ color: "#000", fontWeight: "bold" }}>
              12. Métiers manuels et artisanaux 🛠️🎨
            </ListSubheader>
            <MenuItem value="carpenter">Menuisier</MenuItem>
            <MenuItem value="plumber">Plombier</MenuItem>
            <MenuItem value="cabinetmaker">Ébéniste</MenuItem>
            <MenuItem value="blacksmith">Forgeron</MenuItem>
            <MenuItem value="upholsterer">Tapissier</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: "100%",
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.ageGroup")}
          </InputLabel>
          <Select
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.ageGroupPreferences}
            onChange={handleChange("ageGroupPreferences")}
            label={t("teacherForm.ageGroup")}
          >
            <MenuItem value="3-5-years-old">3-5 years old</MenuItem>
            <MenuItem value="6-8-years-old">6-8 years old</MenuItem>
            <MenuItem value="9-11-years-old">9-11 years old</MenuItem>
            <MenuItem value="12-15-years-old">12-15 years old</MenuItem>
            <MenuItem value="16-18-years-old">16-18 years old</MenuItem>
            <MenuItem value="18-plus-years-old">+ 18ans</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("appointmentForm.learningStyle")}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={values.learningStyles}
            label={t("appointmentForm.learningStyle")}
            onChange={handleChangeMultiple("learningStyles")}
            multiple
            input={<OutlinedInput label="Learning Style" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {learningStyle.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox
                  checked={values.learningStyles.indexOf(item) > -1}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handleChangeMultiple("learningStyle")({
                      target: {
                        value: checked
                          ? [...values.learningStyles, item]
                          : values.learningStyles.filter(
                              (value) => value !== item,
                            ),
                      },
                    });
                  }}
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("teacherForm.hobby")}
          </InputLabel>
          <Select
            sx={{ width: "100%", minWidth: "50%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values?.hobbies}
            onChange={handleChange("hobbies")}
            label={t("teacherForm.hobby")}
            multiple
          >
            {hobbies.map((hobby) => (
              <MenuItem key={hobby.key} value={hobby.key}>
                {t(`hobbyList.${hobby.key}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <TextField
            label={t("teacherForm.summary")}
            value={values.summary}
            onChange={handleChange("summary")}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", sm: "100%", xl: "100%" },
            mt: 3,
          }}
        >
          <TextField
            label={t("teacherForm.webDesc")}
            value={values.webDesc}
            onChange={handleChange("webDesc")}
          />
        </FormControl>
      </Div>

      <Stack sx={{ width: "100%" }} spacing={2}>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
      </Stack>
      <Div sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          onClick={() => {
            hide();
          }}
          sx={{ mr: 1 }}
        >
          {t("common.closeBtn")}
        </Button>
        <Button onClick={backToStep2} sx={{ mr: 1 }}>
          {t("common.backBtn")}
        </Button>
        <Div sx={{ flex: "1 1 auto" }} />
        <Button variant={"contained"} onClick={submitStep3}>
          {t("common.nextBtn")}
        </Button>
      </Div>
    </Box>
  );
};

export default Step3;
