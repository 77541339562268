import React, {useEffect} from 'react';
import Div from "@jumbo/shared/Div";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useAppMutation} from "../../../services";
import parentServices from "../../../services/parent-services";
import {useTranslation} from "react-i18next";
import {countryFormattingRules, PHONE_COUNTRIES} from "../../../utils/constants/appActions";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useLocation, useNavigate} from "react-router-dom";

const Signup = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
            <InnerSignup/>
        </GoogleReCaptchaProvider>
    );
};

const InnerSignup = () => {
        const {mutate: signUp, error, isError, isLoading, data: result, isSuccess} = useAppMutation(parentServices.signUp);
        const {t} = useTranslation();
        const [open, setOpen] = React.useState(false);
        const [errMsg, setErrMsg] = React.useState('');
        const {executeRecaptcha} = useGoogleReCaptcha();
        const navigate = useNavigate();
        const location = useLocation();


        useEffect(() => {
                if (isSuccess) {
                    window.dataLayer = window.dataLayer || [];
                    const {email, firstName, lastName, phone, country} = values;
                    const {code, countryCode, label} = country;
                    console.log(values);
                    window.dataLayer.push({
                        'event': 'ParentSignUp',
                        parentEmail : email,
                        parentFirstName: firstName,
                        parentLastName: lastName,
                        parentPhone: phone,
                        parentCountry: label
                    });
                    navigate('/parent/signup/success');
                    // console.log(isSuccess);
                }
                if (isError && error) {
                    if (error && error.includes('User Already Exists')) {
                        setErrMsg(t('parent-signup.title.alreadyEmailMsg'));
                        setTimeout(() => {
                            setErrMsg("");
                        }, 3000);
                    } else {
                        setErrMsg(t('parent-signup.title.signUpErrorMsg'));
                        setTimeout(() => {
                            setErrMsg("");
                        }, 3000);
                    }
                }
                console.log('Signup-----------------------', isError, error, isSuccess, result);
            }, [isSuccess, isError]
        )
        ;

        const [values, setValues] = React.useState({
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            country: {
                countryCode: 'FR',
                label: 'France',
                code: '33',
                suggested: true,
            },
            phone: '',
            showPassword: false,
            subscribeNewsLetter: false,
            terms: false
        });

        const handleChange = (prop) => (event) => {
            let value = event.target.value;

            // Remove leading zeros from the phone number
            if (prop === 'phone') {
                value = value.replace(/^0+/, '');
            }

            const selectedCountry = values.country.code;
            const formattingRule = countryFormattingRules.find(rule => rule.code === selectedCountry);

            if (formattingRule) {
                const matches = value.match(formattingRule.pattern);

                if (matches) {
                    // Remove undefined and join the matched groups with the specified format
                    value = matches.slice(1).filter(match => match !== undefined).join(' ');
                }
            }

            setValues({...values, [prop]: value});
        };

        const handleCountryChange = (event, newValue) => {
            console.log(newValue);
            setValues({...values, country: newValue});
        };

        const handleSubsribeChange = (event) => {
            setValues({...values, subscribeNewsLetter: event.target.checked});
        };

        const handleTermChange = (event) => {
            setValues({...values, terms: event.target.checked});
        };

        const handleClickShowPassword = () => {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        };

        const handleChangePhone = (event) => {
            let inputNumber = event.target.value;

            const sanitizedNumber = inputNumber.replace(/\D/g, '');

            let phone = sanitizedNumber;

            // Ensure the first digit is not 0
            phone = phone.replace(/^0+/, '');

            // Get selected country code
            const selectedCountryCode = values.country.code;

            // Find the formatting rule for the selected country code
            const countryFormattingRule = countryFormattingRules.find(rule => rule.code === selectedCountryCode);

            // Enforce maximum digits restriction based on country code
            if (countryFormattingRule) {
                const maxDigits = countryFormattingRule.maxDigits;

                // If the input length exceeds the maximum allowed digits, truncate the input
                if (phone.length > maxDigits) {
                    phone = phone.slice(0, maxDigits);
                }

                if (phone.length === maxDigits) {
                    event.preventDefault();
                }
            }

            setValues({...values, phone});
        };

        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };

        const handleSignup = () => {
            console.log(values);
            setErrMsg('');

            const fullPhoneNumber = `+${values.country.code}${values.phone}`;
            const fullNumberRegex = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3,}[-\s\.]?[0-9]{4,6}$/im;

            const fullPhoneNumberWithoutSpaces = fullPhoneNumber.replace(/\s/g, '');

            if (
                !values.email ||
                !values.firstName ||
                !values.lastName ||
                !values.password ||
                !values.country ||
                !values.phone
            ) {
                setErrMsg(t('parent-signup.title.requiredFieldErrorMsg'));
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            } else if (!values.terms) {
                setErrMsg(t('parent-signup.title.termsErrorMsg'));
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
            } else if (!fullNumberRegex.test(fullPhoneNumberWithoutSpaces)) {
                setErrMsg("Invalid Full Phone Number");
                setTimeout(() => {
                    setErrMsg("");
                }, 3000);
                return;
            } else {

                if (!executeRecaptcha) {
                    console.log("Execute recaptcha not yet available");
                    return;
                }

                executeRecaptcha("parentSigunForm").then((gReCaptchaToken) => {
                    const validatedFullPhoneNumber = fullPhoneNumberWithoutSpaces;
                    const obj = {
                        ...values,
                        location: {country: values.country.code},
                        phone: {
                            fullNumber: fullPhoneNumberWithoutSpaces,
                            code: values.country.code,
                            number: values.phone
                        },
                        token: gReCaptchaToken
                    };
                    console.log("SignUp------------", obj);
                    signUp(obj);
                });

            }
        };

        if (location.pathname === '/parent/signup/success' || isSuccess) {
            return (
                <>
                    <Div sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 700,
                    }}>
                        <Card sx={{padding: 3, width: 600, height: 280}}>
                            <CardContent sx={{textAlign: 'center',}}>
                                <img src="/images/logos/logo.png" alt="Logo" width="150"/>
                                <Typography variant="h4" color="success" fontWeight={500} mb={2}>
                                    {t('parent-signup.title.successMsg')}
                                </Typography>
                                <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src="/images/logos/success2.png" alt="Success" width="50"
                                         style={{marginBottom: '10px'}}/>
                                    <Typography variant={"body1"} mb={0} mr={3}>
                                        {t('parent-signup.title.clickTo')}
                                        <Link
                                            href="/parent/login"
                                            color={"inherit"}
                                            underline={'none'}
                                            sx={{
                                                '&:hover': {
                                                    color: '#454545', // Change the color to red on hover
                                                },
                                                textDecoration: 'underline',
                                                ml: 0.5
                                            }}
                                        >
                                            {t('parent-signup.title.login')}
                                        </Link>
                                    </Typography>
                                </Div>
                            </CardContent>
                        </Card>
                    </Div>

                </>
            );
        }

        return (
            <Div sx={{
                width: 720,
                maxWidth: '100%',
                margin: 'auto',
                p: 4
            }}>
                <Card
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: {xs: 'column', md: 'row'}
                    }}
                >
                    <CardContent
                        sx={{
                            flex: 1,
                            p: 4
                        }}
                    >
                        <Div sx={{
                            mb: 2, // Adjust margin as needed
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <img src="/images/logos/logo.png" alt="Logo" width="150"/>
                        </Div>

                        <Div sx={{mt: 1, display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500}>
                                {t('parent-signup.title.signupHeading')}
                            </Typography>
                        </Div>

                        <Div sx={{mt: 1, mb: 2}}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    required
                                    id="email"
                                    label={t('common.email')}
                                    InputLabelProps={{shrink: true}}
                                    fullWidth
                                    value={values.email}
                                    onChange={handleChange('email')}
                                />
                            </FormControl>
                        </Div>
                        <Div sx={{mt: 1, mb: 2}}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    required
                                    id="firstName"
                                    label={t('common.firstName')}
                                    fullWidth
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                />
                            </FormControl>
                        </Div>

                        <Div sx={{mt: 1, mb: 2}}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    required
                                    id="lastName"
                                    label={t('common.lastName')}
                                    fullWidth
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                />
                            </FormControl>
                        </Div>

                        <Div sx={{mt: 1, mb: 2}}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password" shrink>{t('common.password')}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t('common.password')}
                                    required
                                />
                            </FormControl>
                        </Div>

                        <Div sx={{mt: 1, mb: 2, display: 'flex', alignItems: 'center'}}>
                            <FormControl variant="outlined" sx={{flex: '0 0 130px', marginRight: 2}}>
                                <Autocomplete
                                    defaultValue={values.country}
                                    id="country-select-demo"
                                    options={PHONE_COUNTRIES}
                                    autoHighlight
                                    onChange={handleCountryChange}
                                    getOptionLabel={(option) => {
                                        return ' +' + option.code;
                                    }}
                                    disableClearable={true}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label} ({option.countryCode}) +{option.code}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('parent-signup.title.country')}
                                            required
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <React.Fragment>
                                                        {values.country && (
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${values.country.countryCode.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${values.country.countryCode.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl variant="outlined" sx={{flex: 1}}>
                                <TextField
                                    required
                                    id="phone"
                                    label={t('parent-signup.title.phone')}
                                    fullWidth
                                    value={values.phone}
                                    onChange={handleChangePhone}
                                />
                            </FormControl>
                        </Div>

                        <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.subscribeNewsLetter}
                                        onChange={handleSubsribeChange}
                                        sx={{
                                            color: 'gray',
                                            '&.Mui-checked': {
                                                color: '#454545',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {t('parent-signup.title.subscriptionMsg')}
                                    </Typography>
                                }
                            />
                        </Div>

                        <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.terms}
                                        onChange={handleTermChange}
                                        sx={{
                                            color: 'gray',
                                            '&.Mui-checked': {
                                                color: '#454545',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {t('parent-signup.title.text1')} <a
                                        href="https://app.deliverydigital.fr/conditions-generales-dutilisation/" target="_blank"
                                        rel="noopener noreferrer">{t('parent-signup.title.text2')}</a> {t('parent-signup.title.text3')}
                                        <a href="https://app.deliverydigital.fr/politique-de-confidentialite/" target="_blank"
                                           rel="noopener noreferrer">{t('parent-signup.title.text4')}</a> {t('parent-signup.title.text5')}
                                    </Typography>
                                }
                            />
                        </Div>
                        {/*   <Div sx={{display: 'flex', alignItems: 'center', mb: 2, ml: 1}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.terms}
                                    onChange={handleTermChange}
                                    sx={{
                                        color: 'gray',
                                        '&.Mui-checked': {
                                            color: '#454545',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    Termes et conditions{' '}
                                    <Link href="https://app.deliverydigital.fr/conditions-generales-dutilisation/" target="_blank" rel="noopener">
                                        https://app.deliverydigital.fr/conditions-generales-dutilisation/
                                    </Link>
                                </Typography>
                            }
                        />
                    </Div> */}

                        <Stack
                            sx={{
                                mb: 2,
                                width: '90%',
                                mx: 'auto',
                                display: 'block',
                                position: 'relative',
                            }}
                            spacing={2}>
                            {errMsg && <Alert severity="error">{errMsg}</Alert>}
                        </Stack>

                        <Button
                            variant="contained"
                            sx={{
                                mb: 2,
                                width: '90%',
                                mx: 'auto',
                                display: 'block',
                                position: 'relative',
                            }}
                            onClick={handleSignup}
                            disabled={isLoading}
                        >
                            {isLoading && (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: '#FFFFFF',
                                        position: 'absolute',
                                        top: '50%',
                                        right: '35%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            {isLoading ? t('parent-signup.title.signingupBtn') : t('parent-signup.title.signupBtn')}
                        </Button>

                        <Div sx={{mt: 1, display: 'flex', justifyContent: 'center'}}>
                            <Typography variant={"body1"}>
                                {t('parent-signup.title.alreadyMsg')}
                                <Link
                                    href="/parent/login"
                                    color={"inherit"}
                                    underline={'none'}
                                    sx={{
                                        '&:hover': {
                                            color: '#454545',
                                        },
                                        ml: 0.5
                                    }}
                                >
                                    {t('parent-signup.title.signIn')}
                                </Link>
                            </Typography>
                        </Div>
                    </CardContent>
                </Card>
            </Div>
        );
    }
;

export default Signup;
