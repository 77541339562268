import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppMutation } from "../../../../services";
import parentServices from "../../../../services/parent-services";
import { timings, timingSlots } from "../../../../utils/constants/appActions";
import moment from "moment/moment";
import Div from "@jumbo/shared/Div";
import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";

const AddBookingByTeacher = ({
  availableDates: dates,
  type,
  pastDate,
  values,
  setValues,
  minDate,
  maxDate,
  setMaxDate,
}) => {
  const [availableDates, setAvailableDates] = useState(dates);
  const { t } = useTranslation();
  const [sTimings, setSTimings] = useState([]);
  const [search, setSearch] = useState();
  const [open, setOpen] = React.useState(false);
  const [teacher, setTeacher] = React.useState();
  const [teachers, setTeachers] = React.useState([]);

  const {
    mutate: loadAvailableTeachersBySearch,
    isSuccess: isLATSuccess,
    isError: isLATError,
    data: latData,
  } = useAppMutation(parentServices.loadAvailableTeachersBySearch);

  const {
    mutate: loadAvailableTimesByTeacher,
    isSuccess: isLTSuccess,
    isLoading: isLTLoading,
    isError: isLTError,
    data: ltResult,
  } = useAppMutation(parentServices.loadAvailableTimesByTeacher);

  const {
    mutate: loadAvailableDatesByTeacher,
    isSuccess: isLDSuccess,
    isLoading: isLDLoading,
    isError: isLDError,
    data: ldResult,
  } = useAppMutation(parentServices.loadAvailableDatesByTeacher);

  React.useEffect(() => {
    if (isLDSuccess) {
      setAvailableDates(ldResult?.availableDates);
      setValues({ ...values, startTime: undefined });
      setSTimings([]);
      loadAvailableTimesByTeacher({
        date: moment(values.sstartDate).format("YYYY-MM-DD"),
        teacherId: values.teacherId,
        type,
        duration: values.duration ?? 30,
      });
    }
  }, [isLDSuccess, values.duration]);

  React.useEffect(() => {
    if (isLATSuccess) {
      setTeacher();
      setSTimings([]);
      setTeachers(latData.availableTeachers);
    }
  }, [isLATSuccess]);
  React.useEffect(() => {
    if (isLTSuccess && ltResult) {
      const ls = [];
      for (const t of ltResult?.timings) {
        let date = new Date(`${values.startDate}T${t}`);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        date = new Date(date.getTime() - userTimezoneOffset);
        if (date > new Date()) {
          let label1 = moment(date).format("HH:mm");
          date.setMinutes(date.getMinutes() + values.duration);
          let label2 = moment(date).format("HH:mm");
          const obj = { label: `${label1} - ${label2}`, value: label1 };
          ls.push(obj);
        }
      }
      setSTimings(ls);
    } else {
      setSTimings([]);
    }
  }, [isLTError, isLTSuccess]);

  React.useEffect(() => {
    if (maxDate) {
      loadEvaluators({});
    }
  }, [maxDate]);

  React.useEffect(() => {
    if (type) {
      // loadEvaluators({});
    }
  }, [type]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "startTime") {
      // loadEvaluators({startTime: event.target.value});
    } else if (prop === "startDate") {
      // loadEvaluators({startDate: event.target.value});
    }
  };

  const loadEvaluators = ({}) => {
    const selectedStartDateTime = new Date(minDate);
    const selectedEndDateTime = new Date(maxDate);
    const obj = {
      type,
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime,
      transactionId: values.transactionId,
    };
    loadAvailableTeachersBySearch(obj);

    /*const sDate = startDate ? '' + startDate : '' + values.startDate;
        const sTime = startTime ? '' + startTime : '' + values.startTime;
        const c = childId ? childId : values.childId;
        const selectedStartDateTime = new Date(`${sDate}T${sTime}`);
        const selectedEndDateTime = new Date(`${sDate}T${sTime}`);

        selectedEndDateTime.setMinutes(selectedEndDateTime.getMinutes() + 29);

        const obj = {type, childId: c, startDate: selectedStartDateTime, endDate: selectedEndDateTime}
        loadAvailableTeachersBySearch(obj);*/
  };

  return (
    <Div>
      <FormControl
        fullWidth
        sx={{
          width: { xs: "100%", xl: "100%", sm: "100%", md: "100%" },
          mt: 2,
        }}
      >
        {
          <Autocomplete
            id="parents-select"
            value={teacher?.teacherId ? teacher : null}
            noOptionsText={"Aucun enregistrement"}
            onChange={(event, newValue, v) => {
              console.log(event, newValue, v);
              setValues({
                ...values,
                teacherId: newValue?.teacherId,
                startTime: undefined,
              });
              setTeacher(newValue);
              loadAvailableDatesByTeacher({
                startDate: minDate,
                endDate: maxDate,
                type,
                teacherId: newValue?.teacherId,
              });
            }}
            filterSelectedOptions
            options={teachers}
            getOptionLabel={(option) =>
              option?.firstName
                ? `${option?.firstName} ${option?.lastName}`
                : t("parent.title.teacher")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={teachers.length === 0 ? t("parent.title.teacher") : ""}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  // handleSearch({search: e.target.value});
                }}
              />
            )}
          />
        }
      </FormControl>
      <FormControl
        fullWidth
        sx={{
          width: { xs: "100%", xl: "100%", sm: "100%", md: "100%" },
          mt: 3,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            {availableDates && availableDates.length > -1 && (
              <DesktopDatePicker
                disabled={pastDate}
                open={open}
                onOpen={(event) => {
                  setOpen(true);
                }}
                onClose={(event) => {
                  setOpen(false);
                }}
                shouldDisableDate={(day) => {
                  console.log(availableDates.length);
                  if (pastDate) {
                    console.log("pastDate", day);
                    return false;
                  } else if (
                    availableDates &&
                    availableDates.indexOf(moment(day).format("YYYY-MM-DD")) >
                      -1
                  ) {
                    const currentDate = new Date();
                    if (
                      moment(currentDate).format("YYYY-MM-DD") ===
                      moment(day).format("YYYY-MM-DD")
                    ) {
                      console.log("availableDates", day);
                      return true;
                    }
                    return false;
                  } else {
                    return true;
                  }
                }}
                minDate={pastDate ? null : minDate}
                maxDate={pastDate ? null : maxDate}
                loading={false}
                label={t("common.date")}
                format="dd/MM/yyyy"
                value={values.sstartDate}
                onChange={(event, newValue) => {
                  const startDate = moment(event).format("YYYY-MM-DD");
                  const sstartDate = event;
                  setValues({
                    ...values,
                    startDate,
                    sstartDate,
                    startTime: undefined,
                  });
                  setMaxDate(null);
                  loadAvailableTimesByTeacher({
                    date: startDate,
                    teacherId: values.teacherId,
                    type,
                    duration: values.duration ?? 30,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          </Stack>
        </LocalizationProvider>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="timing-slot-label">Duration</InputLabel>
        <Select
          labelId="timing-slot-label"
          id="timing-slot-select"
          value={values.duration || ""}
          label="Duration"
          onChange={handleChange("duration")}
        >
          {timingSlots.map((item, index) => (
            <MenuItem key={`slot-${index}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
        <InputLabel id="demo-simple-select-label">
          {t("parent.title.time")}
        </InputLabel>
        <Select
          disabled={!values?.startDate || !values?.duration}
          //disabled={!values?.startDate}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={values.startTime}
          label={t("common.time")}
          onChange={handleChange("startTime")}
        >
          {sTimings.map((item, index) => (
            <MenuItem key={"key-timing-" + index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Div>
  );
};

export default AddBookingByTeacher;
